/* File: src/components/styles/HomePage.css */

/* Stili per HomePage */
.homepage {
  font-family: 'Inter', sans-serif;
  color: #2b2e34;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
}

/* Sezione 1 */
.section-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
  text-align: center;
  background-color: #fff;
  position: relative;
}

.section-one h1 {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: clamp(30px, 6vw, 65px);
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  line-height: clamp(26px, 6vw, 80px);
  word-wrap: break-word;
  background: linear-gradient(180deg, #df4634, #000000);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-background-clip: text;
  color: transparent;
}

.section-one p {
  font-size: clamp(16px, 2vw, 24px);
  font-weight: 500;
  line-height: clamp(18px, 3vw, 32px);
  margin-bottom: 20px;
  color: #2b2e34;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 1rem;
}

.try-minerva-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(150px, 30%, 250px);
  height: clamp(40px, 5vw, 60px);
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  border: 1px #df4634 solid;
  text-decoration: none;
  cursor: pointer;
  color: #2b2e34;
  font-size: clamp(16px, 3vw, 28px);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: clamp(24px, 4vw, 40px);
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  margin-top: 1rem;
}

.try-minerva-button:hover {
  transform: scale(1.05);
  background-color: #df4634;
  color: white;
}

.try-minerva-button:active {
  transform: scale(0.95);
  transition: transform 0.1s ease;
}

.image-container {
  position: relative;
  margin-top: 3rem;
  width: 70vw;
  max-width: 1100px;
  height: auto;
  aspect-ratio: 16/9;
}

.teacher-image {
  width: 50% !important;
  height: auto;
  position: absolute;
  top: 10%;
  left: -20%;
  z-index: 2;
  object-fit: contain;
}

.child-image {
  width: clamp(200px, 80%, 463px);
  position: absolute;
  bottom: 1.5%;
  right: 4%;
  margin-bottom: 10%;
  margin-right: clamp(-30%, 0%, 0%);
}

/* Sezione 2 */
.section-two {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 50px 2%;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 2%;
}

.text-content {
  flex: 1 1 50%;
  padding-right: -1%;
}

.section-two-logo {
  width: 14vw;
  max-width: 250px;
  margin-bottom: -10%;
  margin-left: -75%;
}

.section-two h2 {
  color: #2b2e34;
  font-size: clamp(24px, 4.9vw, 64px);
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  line-height: clamp(20px, 1.2em, 60px);
  margin-bottom: 0.01rem;
  text-align: left;
  padding: 5%;
}

.section-two p {
  color: #2b2e34;
  font-size: clamp(14px, 1.8vw, 24px);
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  line-height: clamp(18px, 1.7em, 40px);
  text-align: left;
  padding: 0% 5%;
}

.image-content {
  flex: 1 1 50%;
  position: relative;
}

.gradient-box {
  width: 100%;
  height: 30%;
  background: linear-gradient(180deg, #df4634 0%, #79261c 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px 0px 0px 30px;
  position: absolute;
  bottom: 0;
  right: -4.25%;
  padding: 0;
  margin: 0;
}

/* Animazioni */
@keyframes backgroundMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Preferenze di Riduzione delle Animazioni */
@media (prefers-reduced-motion: reduce) {
  /* Disabilita o riduci le animazioni */
  .try-minerva-button:hover,
  .try-minerva-button:active {
    transform: none;
    background-color: #df4634;
    color: white;
  }

  .section-one,
  .section-two {
    opacity: 1;
    transform: none;
  }

  .teacher-image,
  .child-image {
    opacity: 1;
    transform: none;
  }
}
