/* src/components/styles/Resources.css */

.resourcesPage_MainContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f5f5; /* Sfondo chiaro per contrastare il contenuto */
}

/* Stili per il thumbnails */
.thumbnailImage_UniqueImg {
  width: 100%;
  height: 100%;
  object-fit: cover; /* L'immagine copre l'intero contenitore mantenendo l'aspect ratio */
  object-position: top; /* L'immagine è posizionata in alto */
  border-radius: 8px; /* Mantieni la consistenza con il box */
  transition: transform 0.3s ease;
}

.documentCategory_UniqueBox:hover .thumbnailImage_UniqueImg {
  transform: scale(1.05); /* Aggiungi un leggero ingrandimento al passaggio del mouse */
}

.resourcesPage_ContentContainer {
  flex: 1;
  padding: 20px;
  padding-bottom: 60px; /* Aumenta lo spazio inferiore */
  display: flex;
  justify-content: center; /* Centra orizzontalmente */
  align-items: center; /* Centra verticalmente */
}

.resourcesPage_MainContent {
  width: 100%;
  max-width: 1200px; /* Limita la larghezza massima */
}

.resourcesHeader_UniqueSection {
  text-align: center;
  margin-bottom: 40px;
}

.resourcesHeader_UniqueSection h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.resourcesHeader_UniqueSection p {
  font-size: 1.2rem;
  color: #555;
}

.resourcesList_UniqueSection {
  width: 100%;
  margin-bottom: 60px; /* Aggiunge spazio extra sotto la lista di risorse */
}

.resourcesList_LoadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.categoriesContainer_UniqueWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

/* Nuovo stile per la griglia di risorse */
.resourcesGrid_Display {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Due colonne di larghezza uguale */
  gap: 24px; /* Spazio tra le card */
  max-width: 1200px; /* Limita la larghezza massima */
}

/* Stile per ogni item nella lista delle risorse */
.resourceItem_Box {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: all 0.25s ease;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.06);
  height: 100%; /* Per assicurarsi che tutte le card nella stessa riga abbiano la stessa altezza */
  display: flex;
  min-height: 380px; /* Altezza minima per assicurare spazio per i tag */
}

.resourceItem_Box:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  border-color: rgba(223, 70, 52, 0.3);
}

/* Container principale della card */
.resourceCard_Container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column; /* In questo layout, l'immagine sarà sopra e le informazioni sotto */
}

/* Container dell'immagine */
.resourceImage_Container {
  width: 100%;
  height: 180px; /* Altezza fissa per le immagini */
  flex-shrink: 0;
  position: relative;
  background-color: #f0f0f0;
}

.resourceThumbnail_Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: transform 0.3s ease-in-out;
}

.resourceImage_Container:hover .resourceThumbnail_Img {
  transform: scale(1.05);
}

.resourceThumbnail_Placeholder {
  width: 100%;
  height: 100%;
  background-color: #DF4634;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
}

/* Contatore pagine */
.resourcePages_Count {
  position: absolute;
  bottom: 8px;
  left: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 0.7rem;
  padding: 4px 8px;
  border-radius: 12px;
}

/* Container delle informazioni */
.resourceInfo_Container {
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Evita che il contenuto fuoriesca dalla card */
}

/* Header con titolo e prezzo */
.resourceHeader_Container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
}

.resourceTitle_Wrapper {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1;
}

.resourceTitle_Text {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  margin: 0;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.resourcePrice_Text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  white-space: nowrap;
}

.premiumIcon_Small {
  width: 20px;
  height: 20px;
}

/* Stile per l'autore */
.resourceAuthor_Text {
  margin-bottom: 8px;
  font-size: 0.9rem;
  color: #df4634;
}

/* Stile per la descrizione */
.resourceDescription_Text {
  margin-bottom: 16px;
  color: #555;
  font-size: 0.85rem;
  line-height: 1.4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex-grow: 1; /* Fa espandere la descrizione per occupare lo spazio disponibile */
}

/* Container per i tag */
.resourceTags_Container {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.resourceTagsGroup {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.resourceTagsList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  width: 100%;
}

.resourceTagLabel {
  color: #666;
  font-size: 0.7rem;
  font-weight: 600;
  margin-right: 4px;
  white-space: nowrap;
}

.resourceTag {
  background-color: rgba(223, 70, 52, 0.1);
  color: #df4634;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.7rem;
  white-space: nowrap;
  transition: background-color 0.2s ease;
  border: 1px solid rgba(223, 70, 52, 0.2);
}

.resourceTag:hover {
  background-color: rgba(223, 70, 52, 0.2);
}

.resourceTagMore {
  color: #666;
  font-size: 0.7rem;
}

.resourceFormat {
  background-color: #2e7d32;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.7rem;
  text-transform: lowercase;
  margin-left: auto;
  display: flex;
  align-items: center;
}

/* Media Queries */
@media (max-width: 992px) {
  .resourcesGrid_Display {
    grid-template-columns: repeat(2, 1fr); /* Manteniamo due colonne su tablet */
    gap: 16px;
  }
  
  .resourceImage_Container {
    height: 160px; /* Riduciamo leggermente l'altezza delle immagini */
  }
  
  .resourceDescription_Text {
    -webkit-line-clamp: 2; /* Meno righe di descrizione su tablet */
  }
}

@media (max-width: 768px) {
  .resourcesGrid_Display {
    grid-template-columns: 1fr; /* Una sola colonna su mobile */
    max-width: 600px; /* Limitiamo la larghezza massima per evitare layout troppo larghi */
    margin: 0 auto;
  }
  
  .resourceItem_Box {
    min-height: auto; /* Rimuoviamo l'altezza minima su mobile */
  }
  
  .resourceCard_Container {
    flex-direction: row; /* Torniamo al layout orizzontale su mobile */
    min-height: 160px;
  }
  
  .resourceImage_Container {
    width: 140px;
    height: 100%;
  }
  
  .resourceHeader_Container {
    flex-direction: column;
    gap: 4px;
  }
  
  .resourcePrice_Text {
    margin-top: 0;
  }
  
  .resourceTags_Container {
    margin-top: 8px;
    gap: 8px;
  }
  
  .resourceDescription_Text {
    -webkit-line-clamp: 2;
    margin-bottom: 8px;
  }
  
  .resourceTagsList {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .resourcesPage_ContentContainer {
    padding: 12px;
  }
  
  .resourceTitle_Text {
    font-size: 0.9rem;
  }
  
  .resourcePrice_Text {
    font-size: 0.9rem;
  }
  
  .resourceImage_Container {
    width: 120px;
  }
  
  .resourceDescription_Text {
    -webkit-line-clamp: 2;
    font-size: 0.8rem;
  }
  
  .resourceAuthor_Text {
    font-size: 0.8rem;
  }
  
  .resourceTag {
    font-size: 0.65rem;
    padding: 2px 6px;
  }
  
  .resourceTagLabel {
    font-size: 0.65rem;
  }
}