/* src/components/Profile/styles/ProfileDetails.css */

/* Box principale del profilo */
.profile-box {
  background: linear-gradient(180deg, #DF4634 0%, #000000 100%);
  border-radius: 1.875rem; /* 30px */
  color: white;
  position: relative;
  padding: 2.5rem 1.25rem 1.25rem 1.25rem; /* 40px 20px 20px 20px */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1.25rem; /* 20px */
  height: 100%;
}

/* Icona di modifica */
.modify-icon {
  position: absolute;
  top: 1.25rem; /* 20px */
  right: 1.25rem; /* 20px */
  width: 1.5rem; /* 24px */
  height: 1.5rem; /* 24px */
  cursor: pointer;
  transition: transform 0.3s;
}

.modify-icon:hover {
  transform: scale(1.1);
}

/* Sezione Header */
.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem; /* 20px */
}

/* Titolo Utente */
.title {
  font-size: 2rem !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 300 !important;
}

/* Foto Profilo */
.profile-photo {
  width: 8rem; /* 128px */
  height: 8rem; /* 128px */
  border-radius: 50%;
  background-color: #000;
  margin-right: 3rem; /* 48px */
  display: flex;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.profile-photo.hidden {
  opacity: 0;
  visibility: hidden;
}

.black-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #000;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adatta l'immagine senza deformarla */
  border-radius: 50%; /* Mantiene l'immagine rotonda */
}

/* Sezione Informazioni */
.info-section {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* 15px */
  flex: 1;
}

/* Box per ogni informazione */
.info-box {
  display: flex;
  align-items: center;
  gap: 0.625rem; /* 10px */
  justify-content: flex-end; /* Allinea gli elementi a destra */
  width: 100%; /* Occupare tutta la larghezza disponibile */
  padding-right: 10%; /* Come menzionato */
  margin-top: 5%;
}

/* Contenitore per info-box in modalità editing */
.info-box-edit {
  display: flex;
  align-items: center;
  gap: 0.625rem; /* 10px */
  justify-content: flex-end; /* Allinea gli elementi a destra */
  width: 100%; /* Occupare tutta la larghezza disponibile */
  padding-right: 10%; /* Come menzionato */
}

/* Testo delle informazioni */
.info-text {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  text-align: right; /* Allinea il testo a destra */
  flex: 1; /* Consente al testo di occupare lo spazio disponibile */
}

/* Icone per le informazioni */
.icon-image {
  width: 2rem; /* 24px */
  height: 2rem; /* 24px */
}

/* Sezione di editing */
.edit-section {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* 16px di spazio tra i campi */
  width: 100%;
}

/* Stili per i campi di input in modalità modifica */
.edit-textfield {
  width: 100%;
  border-radius: 30px;
  background: linear-gradient(90deg, #62140B 0%, #79261C 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0.2rem; 
}

.edit-textfield .MuiOutlinedInput-root {
  color: #fff !important;
  border-radius: 30px;
}

.edit-textfield .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.edit-textfield .MuiInputLabel-root {
  color: #fff !important;
}

.edit-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.edit-textfield .MuiInputBase-input {
  color: #fff !important;
}

.edit-textfield .MuiInputBase-input::placeholder {
  color: rgba(255, 255, 255, 0.7) !important;
}

.edit-textfield .MuiInputLabel-root.Mui-focused {
  color: #fff !important;
}

/* Bottone Salva personalizzato */
.save-button {
  border-radius: 30px;
  background: linear-gradient(90deg, #62140B 0%, #000 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff !important; /* Testo bianco per contrasto */
  border: 1px solid #fff !important; /* Aggiungi bordo bianco di 1px */
  border-radius: 30px !important;
  padding: 0.625rem 1.25rem; /* 10px 20px */
  align-self: center;
  transition: background 0.3s, color 0.3s;
  cursor: pointer;
}

.save-button:hover {
  background: linear-gradient(90deg, #62140B 0%, #333 100%); /* Cambia gradiente al passaggio del mouse */
  color: #fff !important;
}
