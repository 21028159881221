.accessDeniedContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.accessDeniedContent {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  max-width: 450px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.accessDeniedContent h2 {
  margin-bottom: 20px;
  color: #DF4634; /* Colore coerente con il design esistente */
}

.accessDeniedContent p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 20px;
}

.upgradeButton {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 16px 0;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.upgradeButton:hover {
  background-color: #45a049;
}

/* Stili per dispositivi mobili */
@media (max-width: 480px) {
  .accessDeniedContent {
    width: 95%;
    padding: 16px;
  }
}