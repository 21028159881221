/* ====================================== */
/*       SCRIPTURA GAME (testo/video)     */
/* ====================================== */

.scriptura-game {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    margin: 1rem 0;
}
  
.scriptura-instructions {
    margin-bottom: 1.8rem;
    text-align: center;
}
  
.scriptura-instructions h2 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 0.75rem;
    font-weight: 600;
}
  
.scriptura-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
  
/* Contenitore testuale */
.scriptura-text-container {
    width: 100%;
    max-width: 900px;
    max-height: 550px; /* Aumentata altezza verticale */
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
    overflow-y: scroll; /* Mostra sempre la barra di scorrimento */
    scrollbar-width: thin;
    scrollbar-color: #df4634 #f5f5f5;
    border: 1px solid #f0f0f0;
    position: relative;
}
  
/* Indicatore di scorrimento - ombra sfumata in basso */
.scriptura-text-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to top, rgba(255,255,255,0.9), rgba(255,255,255,0));
    pointer-events: none; /* Permette di scorrere attraverso l'elemento */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
  
/* Indicatore di scorrimento - testo e icona */
.scriptura-scroll-indicator {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #df4634;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 8px 15px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 5;
    pointer-events: none; /* Permette di scorrere attraverso l'elemento */
}
  
.scriptura-scroll-indicator::after {
    content: "↓";
    margin-left: 8px;
    font-size: 1.1rem;
}
  
/* Stili per la barra di scorrimento (WebKit) */
.scriptura-text-container::-webkit-scrollbar {
    width: 8px;
}
  
.scriptura-text-container::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 4px;
}
  
.scriptura-text-container::-webkit-scrollbar-thumb {
    background-color: #df4634;
    border-radius: 4px;
    border: 2px solid #f5f5f5;
}
  
.scriptura-text-container::-webkit-scrollbar-thumb:hover {
    background-color: #c23a2a;
}
  
.scriptura-text-content {
    width: 100%;
    overflow-x: auto;
    white-space: pre-wrap;
    font-size: 1.1rem;
    line-height: 1.8;
    color: #333;
    font-family: 'Roboto', Arial, sans-serif;
}
  
/* Sezione video YouTube */
.scriptura-youtube-container {
    width: 100%;
    max-width: 900px;
    margin-bottom: 2rem;
}
  
.scriptura-youtube-container h3 {
    font-size: 1.5rem;
    margin-bottom: 1.2rem;
    text-align: center;
    color: #2c3e50;
    font-weight: 500;
}
  
.youtube-embed {
    position: relative;
    padding-bottom: 62.25%; /* Leggermente più alto del 16:9 standard (56.25%) */
    width: 100%;
    height: 0;
    margin-bottom: 1.5rem;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}
  
.youtube-embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}
  
/* Pulsante di Scriptura */
.scriptura-button {
    padding: 0.9rem 2rem;
    background-color: #df4634;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 600;
    transition: all 0.3s ease;
    margin-top: 1.5rem;
    box-shadow: 0 2px 8px rgba(223, 70, 52, 0.3);
}
  
.scriptura-button:hover {
    background-color: #c23a2a;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(223, 70, 52, 0.4);
}

.scriptura-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 6px rgba(223, 70, 52, 0.3);
}
  
/* Schermata di successo/conferma */
.scriptura-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5rem;
    text-align: center;
}
  
.scriptura-success h2 {
    font-size: 2.2rem;
    color: #27ae60;
    margin-bottom: 1.2rem;
}
  
.scriptura-success p {
    font-size: 1.2rem;
    color: #5d6d7e;
    margin-bottom: 0.8rem;
}
  
/* Responsive */
@media (max-width: 768px) {
    .scriptura-text-container,
    .scriptura-youtube-container {
        max-width: 100%;
        max-height: 450px; /* Aumentata per mobile */
    }
    
    .scriptura-text-content {
        font-size: 1rem;
    }
    
    .scriptura-instructions h2 {
        font-size: 1.6rem;
    }
}
  
@media (max-width: 480px) {
    .youtube-embed {
        padding-bottom: 65%; /* Ancora più alto per schermi piccoli */
    }
    
    .scriptura-text-content {
        padding: 1rem;
    }
    
    .scriptura-text-container {
        max-height: 380px; /* Aumentata anche qui */
        padding: 1.5rem;
    }
}
  