/* src/components/styles/SearchBar.css */

.searchBar_mainContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 20px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.searchBar_inputWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.searchBar_form {
  flex: 1;
}

.searchBar_input .MuiOutlinedInput-root {
  border-radius: 30px;
  background-color: rgba(223, 70, 52, 0.08);
  transition: all 0.3s ease;
}

/* Stile personalizzato per il placeholder */
.searchBar_input .MuiInputBase-input::placeholder {
  color: rgba(223, 70, 52, 0.5);
  opacity: 1;
  font-style: italic;
  transition: all 0.3s ease;
}

.searchBar_input .MuiInputBase-input:focus::placeholder {
  opacity: 0.7;
}

/* Aggiungo stile per l'hover e il focus dell'input */
.searchBar_input .MuiOutlinedInput-root:hover,
.searchBar_input .MuiOutlinedInput-root.Mui-focused {
  border-color: #df4634;
  background-color: rgba(223, 70, 52, 0.12);
}

.searchBar_input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #df4634;
}

/* Stile per l'icona di ricerca con il nuovo colore */
.searchBar_input .MuiInputAdornment-root .MuiSvgIcon-root {
  color: #df4634;
}

.searchBar_actionButtons {
  display: flex;
  gap: 8px;
}

.searchBar_filterButton,
.searchBar_sortButton {
  white-space: nowrap;
  min-width: 90px;
  border-radius: 20px;
  border-color: #df4634;
  color: #df4634;
  transition: all 0.3s ease;
}

.searchBar_filterButton:hover,
.searchBar_sortButton:hover {
  background-color: rgba(223, 70, 52, 0.08);
}

.searchBar_resultsInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.searchBar_resultsCount {
  color: #666;
}

.searchBar_activeFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.searchBar_activeFilterChip {
  background-color: rgba(223, 70, 52, 0.12);
  color: #df4634;
  font-size: 0.8rem;
  height: 24px;
}

.searchBar_resetButton {
  font-size: 0.8rem;
  color: #df4634;
}

/* Nuovi stili per il pannello dei filtri espandibile */
.searchBar_expandedFilterPanel,
.searchBar_expandedSortPanel {
  margin-top: 8px;
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid rgba(223, 70, 52, 0.2);
  background-color: white;
  overflow: hidden;
  transition: all 0.3s ease;
}

.searchBar_filterTabs {
  background-color: rgba(223, 70, 52, 0.05);
  border-bottom: 1px solid rgba(223, 70, 52, 0.1);
}

.searchBar_filterContent {
  padding: 16px;
  min-height: 100px;
}

.searchBar_tabContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.searchBar_horizontalCheckboxGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px 4px;
}

.searchBar_filterActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 12px 16px;
  background-color: rgba(223, 70, 52, 0.05);
  border-top: 1px solid rgba(223, 70, 52, 0.1);
}

.searchBar_sortOptions {
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

/* Animazioni per i pannelli espandibili */
.MuiCollapse-root {
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
}

/* Media queries per il responsive */
@media (max-width: 768px) {
  .searchBar_inputWrapper {
    flex-direction: column;
    align-items: stretch;
  }
  
  .searchBar_actionButtons {
    justify-content: space-between;
  }
  
  .searchBar_filterButton,
  .searchBar_sortButton {
    flex: 1;
  }
  
  .searchBar_resultsInfo {
    flex-direction: column;
    align-items: flex-start;
  }

  .searchBar_horizontalCheckboxGroup {
    max-height: 250px;
  }
}

/* Stili per tablet */
@media (max-width: 960px) {
  .searchBar_tabContent {
    padding: 12px 8px;
  }
}

/* Stili per schermi molto piccoli */
@media (max-width: 480px) {
  .searchBar_mainContainer {
    padding: 12px;
  }
  
  .searchBar_horizontalCheckboxGroup {
    max-height: 300px;
  }

  .searchBar_filterContent {
    padding: 12px 8px;
  }
} 