/* GameCommunityPage.css */

.game-community-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .game-community-main {
    flex: 1;
    background-color: #f9f9f9;
    padding: 2rem 1rem;
    text-align: center;
  }
  
  .announcement {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .announcement h1 {
    font-size: 2.5rem;
    color: #df4634;
    margin-bottom: 1rem;
  }
  
  .announcement p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
  }
  
  /* Adattamento per dispositivi mobili */
  @media (max-width: 480px) {
    .announcement h1 {
      font-size: 1.8rem;
    }
    
    .announcement p {
      font-size: 1rem;
    }
  }
  