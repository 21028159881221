/* ModelsPage.css */

/* Container principale della pagina */
.models-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Contenuto centrale */
.models-main {
  flex: 1;
  background-color: #f9f9f9;
  margin-bottom: 40px;
}

/* Sezione introduttiva (stile diverso per creare più stacco) */
.intro {
  text-align: center;
  padding: 2rem 1rem;
  margin-bottom: 0;
  background: #df4634;
  color: #fff;
}

.intro h1 {
  font-size: 2rem;
  margin-bottom: 12px;
  font-weight: 600;
}

.intro p {
  font-size: 1rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.5;
}

/* Griglia dei modelli: 3 colonne con larghezza fissa */
.models-grid {
  display: grid;
  grid-template-columns: repeat(3, 300px);
  gap: 16px;
  justify-content: center;
  margin: 0 auto;
  padding: 2rem 0 1.5rem;
}

/* Scheda modello (pulsante intero) */
.model-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 12px;
  text-align: left;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.model-card:hover {
  background-color: #ffecec; 
  transform: scale(1.02);
}

.model-image {
  max-width: 80px;
  height: auto;
  margin-right: 15px;
}

.model-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.model-content h3 {
  margin: 0 0 6px 0;
  color: #333;
  font-size: 1.2rem;
}

.model-description {
  font-size: 1rem;
  color: #666;
  margin: 0;
}

/* Sezione tutorial con 3 step (sotto la griglia) */
.tutorial-steps {
  text-align: center;
  margin: 2rem 0 2.5rem 0;
}

.tutorial-steps h2 {
  font-size: 1.8rem;
  color: #2b2e34;
  margin-bottom: 1rem;
}

.tutorial-steps .subtitle {
  font-size: 1rem;
  color: #555;
  margin-bottom: 2rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
}

.steps-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.step-box {
  background-color: #fff;
  border-radius: 8px;
  width: 300px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.step-box:hover {
  transform: scale(1.03);
}

.step-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 0.8rem;
}

.step-number {
  font-size: 2.2rem;
  color: #df4634;
  margin: 0.5rem 0;
}

.step-text {
  font-size: 1rem;
  color: #333;
  line-height: 1.4;
}

/* Adattamento per schermi piccoli (mobile) */
@media (max-width: 480px) {
  .intro {
    padding: 1.5rem 1rem;
  }
  
  .intro h1 {
    font-size: 1.6rem;
  }
  
  .intro p {
    font-size: 0.9rem;
  }

  /* Per la griglia, invece di una colonna che occupa tutto lo schermo,
     manteniamo le card a dimensione fissa e centrate */
  .models-grid {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  
  .model-card {
    width: 300px;
    max-width: 90%;
    margin: 0 auto;
    flex-direction: row;
  }
  
  /* Riduzione della dimensione dell'immagine nelle card */
  .model-image {
    max-width: 50px;
    margin-right: 10px;
  }
  
  .model-content h3 {
    font-size: 1.1rem;
  }
  
  .model-description {
    font-size: 0.9rem;
  }

  .tutorial-steps h2 {
    font-size: 1.5rem;
  }
  
  .tutorial-steps .subtitle {
    font-size: 0.9rem;
  }
  
  /* Per i box dei passaggi, manteniamo una larghezza fissa e centrata */
  .step-box {
    width: 300px;
    max-width: 90%;
    margin: 0 auto 1.5rem;
  }
  
  .step-number {
    font-size: 1.8rem;
  }
  
  .step-text {
    font-size: 0.95rem;
  }
  
  /* Riduzione e centratura dell'immagine nei passaggi tutorial */
  .step-image {
    max-width: 80%;
    height: auto;
    margin: 0 auto 0.8rem;
  }
}
