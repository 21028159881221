/* Stili per Footer */
.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra gli elementi orizzontalmente */
  padding: 30px;
  background-color: white;
  margin-top: -2rem;
}

.container {
  max-width: 1600px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cta-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.cta-button button {
  padding: 10px 20px;
  font-size: 2.8vw;
  font-weight: 400;
  font-family: Inter;
  color: #fff;
  background-color: #2b2e34;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 150px;
}

.footer-image img {
  width: 30%;
  height: auto;
  position: relative;
  left: -30%;
}

.contact-info {
  text-align: right;
}

.contact-title {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 10px;
  color: #6b7280;
}

.contact-email {
  font-size: clamp(14px, 4vw, 20px);
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin: 0 0 20px;
  color: #2b2e34;
}

.social-icons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.social-icons img {
  width: 30px;
  height: 30px;
}

.company-info {
  margin-bottom: 0.5rem;
  font-size: 0.9em;
  color: #555; /* Puoi cambiare il colore secondo le tue preferenze */
}

.privacy-policy a {
  font-size: 0.8em; /* Dimensione del font ridotta */
  color: #6b7280; /* Colore discreto */
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

/* Media Queries */
@media (max-width: 650px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .cta-button {
    margin-bottom: 1rem;
  }

  .cta-button button {
    font-size: 1rem;
  }

  .footer-image img {
    width: 25%; /* Regola la dimensione secondo le tue esigenze */
    height: auto;
    position: static; /* Rimuove il posizionamento relativo */
    left: 0; /* Resetta lo spostamento */
    margin-bottom: 1rem;
    margin-top: 3rem;
  }

  .contact-info {
    text-align: center;
    margin-top: 1rem;
  }

  .contact-title {
    margin-bottom: 10px;
    font-size: 1rem;
  }

  .contact-email {
    margin-bottom: 20px;
    font-size: 1rem;
  }

  .social-icons {
    justify-content: center;
  }

  .privacy-policy a {
    font-size: 0.7em; /* Ulteriore riduzione per dispositivi mobili */
  }
}
