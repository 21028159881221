/* Base Container */
.score-summary-container {
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  max-width: 1200px;
  margin: 0 auto;
  color: #1a1a1a;
}

.score-summary-container h2 {
  margin-bottom: 2rem;
  color: #1a1a1a;
  text-align: left;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: -0.5px;
}

/* Empty State */
.score-summary-empty {
  text-align: center;
  padding: 4rem 2rem;
  background-color: #fafafa;
  border-radius: 16px;
  border: 1px dashed #e0e0e0;
}

.score-summary-empty h2 {
  margin-bottom: 1rem;
  color: #1a1a1a;
  font-weight: 700;
  text-align: center;
}

.score-summary-empty p {
  margin-bottom: 2rem;
  color: #666;
  font-size: 1.125rem;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
}

/* Total Score Section */
.score-summary-total {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  padding: 2rem;
  background-color: #fafafa;
  border-radius: 16px;
  border: 1px solid #f0f0f0;
}

.score-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 2rem;
  position: relative;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.score-circle::after {
  content: '';
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  border: 2px solid transparent;
  background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0.3)) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.score-high {
  background: linear-gradient(135deg, #34d399 0%, #059669 100%);
}

.score-medium {
  background: linear-gradient(135deg, #fbbf24 0%, #d97706 100%);
}

.score-low {
  background: linear-gradient(135deg, #f87171 0%, #dc2626 100%);
}

.score-circle .score-value {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0.25rem;
  color: #fff !important;
}

.score-circle .score-label {
  font-size: 0.875rem;
  opacity: 0.9;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.score-summary-stats {
  flex-grow: 1;
}

.score-summary-stats p {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  color: #1a1a1a;
  line-height: 1.6;
}

.score-summary-correct-answers {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  background-color: #f0f9ff;
  border-radius: 12px;
  color: #0369a1;
  font-size: 1.125rem;
}

/* Game Cards Grid */
.score-summary-details {
  margin-top: 3rem;
}

.score-summary-details h3 {
  margin-bottom: 1.5rem;
  color: #1a1a1a;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: -0.5px;
}

.score-summary-games-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 1rem 0;
}

.score-summary-card {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid #f0f0f0;
}

.score-summary-card.clickable {
  cursor: pointer;
}

.score-summary-card.clickable:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
}

.score-card-header {
  padding: 1.25rem;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #f0f0f0;
}

.score-card-number {
  width: 36px;
  height: 36px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  background-color: #e0f2fe;
  color: #0369a1;
}

.score-card-title {
  margin: 0;
  font-size: 1.125rem;
  color: #1a1a1a;
  font-weight: 600;
  letter-spacing: -0.3px;
}

.score-card-content {
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.score-card-score {
  width: 64px;
  height: 64px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  font-size: 1.25rem;
}

.score-card-answers {
  padding: 0.5rem 1rem;
  background-color: #f0f9ff;
  border-radius: 8px;
  color: #0369a1;
  font-size: 0.875rem;
  font-weight: 500;
}

.score-card-arrow {
  color: #94a3b8;
  font-size: 1.25rem;
  transition: transform 0.2s ease;
}

.score-summary-card.clickable:hover .score-card-arrow {
  transform: translateX(4px);
  color: #64748b;
}

/* Action Buttons */
.score-summary-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #f0f0f0;
}

.score-summary-button {
  padding: 0.875rem 1.5rem;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 600;
  min-width: 160px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.score-summary-button {
  background-color: #0ea5e9;
  color: #fff;
}

.score-summary-button:hover {
  background-color: #0284c7;
  transform: translateY(-2px);
}

.score-summary-button.reset {
  background-color: #f43f5e;
}

.score-summary-button.reset:hover {
  background-color: #e11d48;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .score-summary-games-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .score-summary-container {
    padding: 1.5rem;
  }
  
  .score-summary-total {
    flex-direction: column;
    text-align: center;
    padding: 1.5rem;
  }
  
  .score-circle {
    width: 100px;
    height: 100px;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
  
  .score-circle .score-value {
    font-size: 2rem;
  }
  
  .score-summary-actions {
    flex-direction: column;
  }
  
  .score-summary-games-grid {
    grid-template-columns: 1fr;
  }
  
  .score-summary-container h2 {
    font-size: 1.75rem;
  }
  
  .score-summary-details h3 {
    font-size: 1.25rem;
  }
}

.score-card-score .score-value {
  color: #fff !important;
}
  