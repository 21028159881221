/* ====================================== */
/*          SELECTGROUP GAME STYLES       */
/* ====================================== */

/* Struttura principale del gioco */
.selectgroup-game {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0.5rem;
  }
  
  .selectgroup-instructions {
    text-align: center;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, #f9f7ff 0%, #f5f5f5 100%);
    border-radius: 12px;
    padding: 1.2rem;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
    border-left: 4px solid #df4634;
    animation: fadeIn 0.6s ease-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .selectgroup-instructions h3 {
    font-size: 1.3rem;
    color: #333;
    margin: 0;
    font-weight: 600;
    letter-spacing: 0.02em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .selectgroup-subtitle {
    margin: 0.8rem 0 0;
    font-size: 0.95rem;
    color: #666;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Area per le parole disponibili */
  .selectgroup-available-words {
    background: white;
    border-radius: 16px;
    padding: 1.8rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }
  
  .selectgroup-available-words:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, #df4634, #f89d88);
    border-radius: 4px 4px 0 0;
  }
  
  .selectgroup-available-words:hover {
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
    transform: translateY(-3px);
  }
  
  .selectgroup-available-words h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1.2rem;
    text-align: center;
    position: relative;
    display: inline-block;
    margin: 0 auto 1.5rem;
    padding-bottom: 0.6rem;
    font-weight: 600;
    width: 100%;
  }
  
  .selectgroup-available-words h3:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background-color: #df4634;
    border-radius: 3px;
  }
  
  .selectgroup-words-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
    min-height: 60px;
    padding: 1rem;
    border-radius: 12px;
    background-color: #fafafa;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.03);
    transition: all 0.2s;
  }
  
  /* Stili delle singole "chip" di parole */
  .selectgroup-word-chip {
    padding: 0.6rem 1.2rem;
    background-color: white;
    border: 1px solid #f0f0f0;
    border-radius: 30px;
    display: inline-flex;
    align-items: center;
    cursor: grab;
    user-select: none;
    transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    color: #444;
    font-weight: 500;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
  }
  
  .selectgroup-word-chip:hover {
    background-color: #df4634;
    color: white;
    transform: translateY(-3px) scale(1.02);
    box-shadow: 0 5px 15px rgba(223, 70, 52, 0.2);
    border-color: #df4634;
  }
  
  .selectgroup-word-chip:active {
    transform: translateY(0) scale(0.98);
    box-shadow: 0 2px 5px rgba(223, 70, 52, 0.1);
  }
  
  /* Stile per parola selezionata */
  .selectgroup-word-chip.selected {
    background-color: #df4634;
    color: white;
    box-shadow: 0 5px 15px rgba(223, 70, 52, 0.3);
    transform: translateY(-3px) scale(1.02);
    border-color: #df4634;
    font-weight: 600;
  }
  
  .selectgroup-word-chip.placed {
    background-color: #fff6f5;
    border-color: rgba(223, 70, 52, 0.3);
    color: #df4634;
    position: relative;
    padding-right: 2rem;
    box-shadow: 0 3px 8px rgba(223, 70, 52, 0.1);
  }
  
  .selectgroup-remove-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    font-size: 1.1rem;
    line-height: 1;
    color: #df4634;
    opacity: 0.7;
    transition: all 0.2s;
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .selectgroup-word-chip.placed:hover .selectgroup-remove-icon {
    opacity: 1;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Layout a colonne per le aree di destinazione */
  .selectgroup-columns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.8rem;
    margin: 1.5rem 0;
  }
  
  .selectgroup-column {
    background: white;
    border-radius: 16px;
    padding: 1.8rem;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.06);
    position: relative;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }
  
  .selectgroup-column:hover {
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
    transform: translateY(-3px);
  }
  
  .selectgroup-column h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
    padding-bottom: 0.8rem;
    position: relative;
    font-weight: 600;
  }
  
  .selectgroup-column h3:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 3px;
    background-color: #19696A;
    border-radius: 3px;
  }
  
  .selectgroup-column:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, #19696A, #2cc2c4);
    border-radius: 4px 4px 0 0;
  }
  
  /* Pulsanti per aggiungere parole ai gruppi */
  .selectgroup-assign-button {
    background-color: #19696A;
    border: none;
    border-radius: 30px;
    padding: 0.7rem 1rem;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 500;
    box-shadow: 0 4px 10px rgba(25, 105, 106, 0.2);
  }
  
  .selectgroup-assign-button:hover {
    background-color: #145758;
    box-shadow: 0 6px 15px rgba(25, 105, 106, 0.3);
    transform: translateY(-2px);
  }
  
  .selectgroup-assign-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(25, 105, 106, 0.2);
  }
  
  /* Modale di selezione del gruppo */
  .selectgroup-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.25s ease-out;
  }
  
  .selectgroup-modal {
    background-color: white;
    border-radius: 16px;
    padding: 2rem;
    width: 90%;
    max-width: 550px;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
    transform: scale(1);
    animation: modalAppear 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  @keyframes modalAppear {
    from { transform: scale(0.9); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  
  .selectgroup-modal h4 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 1.8rem;
    color: #333;
    font-size: 1.3rem;
    position: relative;
    padding-bottom: 0.8rem;
    font-weight: 600;
  }
  
  .selectgroup-modal h4:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #df4634, #f89d88);
    border-radius: 3px;
  }
  
  .selectgroup-modal-groups {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1.2rem;
    margin-bottom: 1.8rem;
  }
  
  .selectgroup-modal-group-btn {
    background-color: white;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    padding: 1rem;
    color: #444;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.25s;
    font-size: 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
  }
  
  .selectgroup-modal-group-btn:hover {
    background-color: #19696A;
    color: white;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(25, 105, 106, 0.2);
    border-color: #19696A;
  }
  
  .selectgroup-modal-group-btn:active {
    transform: translateY(-1px);
  }
  
  .selectgroup-modal-cancel {
    background-color: #f3f3f3;
    border: none;
    border-radius: 30px;
    padding: 0.9rem;
    width: 100%;
    color: #666;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 500;
    font-size: 1rem;
  }
  
  .selectgroup-modal-cancel:hover {
    background-color: #ebebeb;
    color: #df4634;
  }
  
  /* Pulsanti del gioco */
  .selectgroup-button {
    padding: 0.9rem 2rem;
    background-color: #df4634;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: all 0.3s;
    align-self: center;
    min-width: 180px;
    font-weight: 600;
    box-shadow: 0 6px 15px rgba(223, 70, 52, 0.2);
    margin-top: 1rem;
  }
  
  .selectgroup-button:hover {
    background-color: #c23a2a;
    box-shadow: 0 8px 20px rgba(223, 70, 52, 0.3);
    transform: translateY(-3px);
  }
  
  .selectgroup-button:active {
    transform: translateY(-1px);
    box-shadow: 0 4px 10px rgba(223, 70, 52, 0.2);
  }
  
  .selectgroup-button:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
    box-shadow: none;
  }
  
  /* Pulsante "Consegna" (submit-final) */
  .selectgroup-button.submit-final {
    background-color: #27ae60;
    padding: 1rem 2.5rem;
    font-size: 1.2rem;
    box-shadow: 0 6px 15px rgba(39, 174, 96, 0.2);
  }
  
  .selectgroup-button.submit-final:hover {
    background-color: #219653;
    box-shadow: 0 8px 20px rgba(39, 174, 96, 0.3);
  }
  
  /* Messaggi di avviso/errore */
  .selectgroup-warning {
    color: #e67e22;
    text-align: center;
    font-size: 0.95rem;
    margin-top: 1rem;
    background-color: rgba(230, 126, 34, 0.1);
    border-radius: 30px;
    padding: 0.8rem 1.5rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 3px solid #e67e22;
  }
  
  /* Responsività */
  @media (max-width: 768px) {
    .selectgroup-columns {
      grid-template-columns: 1fr;
    }
  
    .selectgroup-column {
      margin-bottom: 1rem;
    }
    
    .selectgroup-modal {
      width: 95%;
      padding: 1.5rem;
    }
    
    .selectgroup-modal-groups {
      grid-template-columns: 1fr;
    }
    
    /* Rende le chip di parole più grandi su mobile per facilità di tocco */
    .selectgroup-word-chip {
      padding: 0.8rem 1.4rem;
      font-size: 1.1rem;
    }
    
    /* Aumenta l'area cliccabile per la rimozione */
    .selectgroup-remove-icon {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.4rem;
    }
    
    .selectgroup-instructions {
      padding: 1rem;
    }
    
    .selectgroup-instructions h3 {
      font-size: 1.1rem;
    }
  }
  
  /* ====================================== */
  /*      RISULTATI AVANZATI SELECTGROUP    */
  /* ====================================== */
  
  .selectgroup-results-container {
    margin-top: 2rem;
  }
  
  .selectgroup-results-container h4 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
    color: #333;
  }
  
  /* Sezioni corrette/errate */
  .selectgroup-section {
    margin-bottom: 2rem;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  }
  
  .selectgroup-section h5 {
    margin-top: 0;
    margin-bottom: 1.2rem;
    font-size: 1.1rem;
    font-weight: 600;
  }
  
  .correct-section {
    background-color: rgba(46, 204, 113, 0.05);
    border: 1px solid rgba(46, 204, 113, 0.2);
  }
  
  .correct-section h5 {
    color: #27ae60;
  }
  
  .incorrect-section {
    background-color: rgba(231, 76, 60, 0.05);
    border: 1px solid rgba(231, 76, 60, 0.2);
  }
  
  .incorrect-section h5 {
    color: #e74c3c;
  }
  
  /* Layout a griglia per i risultati */
  .selectgroup-groups-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
  }
  
  .selectgroup-group-card {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .selectgroup-group-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .selectgroup-group-card.correct {
    border-left: 4px solid #2ecc71;
  }
  
  .selectgroup-group-card.incorrect {
    border-left: 4px solid #e74c3c;
  }
  
  .group-card-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
  }
  
  .group-card-header h6 {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #333;
  }
  
  .group-status-badge {
    padding: 0.3rem 0.8rem;
    border-radius: 30px;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .group-status-badge.correct {
    background-color: rgba(46, 204, 113, 0.15);
    color: #27ae60;
  }
  
  .group-status-badge.incorrect {
    background-color: rgba(231, 76, 60, 0.15);
    color: #e74c3c;
  }
  
  .group-card-content {
    padding: 1rem;
  }
  
  .group-card-content p {
    margin-top: 0;
    margin-bottom: 0.8rem;
    font-size: 0.9rem;
    color: #666;
  }
  
  /* Parole inserite in un gruppo */
  .group-words-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .group-word-chip {
    background-color: #f5f7fa;
    padding: 0.4rem 0.8rem;
    border-radius: 30px;
    font-size: 0.9rem;
    color: #333;
    border: 1px solid #e1e4e8;
  }
  
  /* Messaggio di feedback (es. spiegazione errori) */
  .group-feedback {
    font-style: italic;
    color: #e74c3c;
    background-color: rgba(231, 76, 60, 0.05);
    padding: 0.7rem;
    border-radius: 6px;
    font-size: 0.85rem;
    margin-top: 1rem;
  }
  
  .selectgroup-feedback {
    margin-top: 2rem;
    text-align: center;
  }
  
  .feedback-message {
    font-size: 1.1rem;
    color: #555;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    display: inline-block;
  }
  
  /* ====================================== */
  /*   CLASSI PER I RISULTATI (BREVI)       */
  /* ====================================== */
  .selectgroup-result.correct {
    background-color: #e8f8f5;
    border-left: 4px solid #27ae60;
    position: relative;
    overflow: hidden;
  }
  
  .selectgroup-result.correct::before {
    content: "✅";
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 24px;
    opacity: 0.7;
  }
  
  .selectgroup-result.incorrect {
    background-color: #fdedeb;
    border-left: 4px solid #e74c3c;
    position: relative;
    overflow: hidden;
  }
  
  .selectgroup-result.incorrect::before {
    content: "❌";
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 24px;
    opacity: 0.7;
  }
  
  @media (max-width: 768px) {
    .selectgroup-groups-grid {
      grid-template-columns: 1fr;
    }
    
    .group-card-header {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .group-status-badge {
      margin-top: 0.5rem;
    }
  }
  