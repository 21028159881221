/* ------------------------------------------ */
/*         STILI GENERALI MOBILE MENU        */
/* ------------------------------------------ */
.mobile-menu {
  /* Cambiato da "absolute" a "fixed" per restare fisso in alto a dx */
  position: fixed; 
  top: 45px;          
  right: 0;           
  width: 200px;
  max-width: 300px;
  max-height: 80vh;   /* Limita l'altezza massima */
  border-radius: 30px;
  background: linear-gradient(
    180deg,
    #df4634 0%,
    rgba(172, 54, 40, 0.98) 50%,
    rgba(121, 38, 28, 0.88) 100%
  );
  z-index: 1000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* scroll interno al menu se i contenuti superano 80vh */
}

/* Nasconde il contenuto se i modali sono aperti */
.menu-hidden .mobile-menu-items {
  display: none;
}

/* ------------------------------------------ */
/*     CONTENUTO SCORREVOLE DEL MENU         */
/* ------------------------------------------ */
.mobile-menu-items {
  /* Se vuoi separare l'overflow qui, puoi farlo, ma in genere basta quello
     su .mobile-menu */
}

/* ------------------------------------------ */
/*       PULSANTE CHIUSURA (X)               */
/* ------------------------------------------ */
.close-icon {
  position: absolute; 
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.close-icon img {
  width: 24px;
  height: 24px;
}

/* ------------------------------------------ */
/*     VOCI DEL MENU PRINCIPALE (Link)       */
/* ------------------------------------------ */
.mobile-menu-item {
  color: white;
  text-decoration: none;
  margin-bottom: 15px;
  font-size: clamp(14px, 3vw, 18px);
  font-weight: 300;
  line-height: 1.5;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.mobile-menu-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Divisore */
.mobile-menu-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin: 15px 0;
}

/* ------------------------------------------ */
/*       SOTTOMENU (GIOCHI)                  */
/* ------------------------------------------ */
.mobile-submenu {
  display: flex;
  flex-direction: column;
  margin-left: 20px; /* Indentazione per differenziare dal link Giochi */
  margin-bottom: 10px;
}

.mobile-submenu-item {
  color: #ffffff;
  text-decoration: none;
  font-size: clamp(13px, 3vw, 16px);
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.mobile-submenu-item:hover {
  text-decoration: underline;
}

/* ------------------------------------------ */
/*          MODAL OVERLAY                    */
/* ------------------------------------------ */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 46, 52, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; /* Sopra al mobile menu */
}

/* ------------------------------------------ */
/*         MODAL CONTENT                     */
/* ------------------------------------------ */
.modal-content {
  background: linear-gradient(
    180deg,
    #df4634 0%,
    rgba(172, 54, 40, 0.98) 50%,
    rgba(121, 38, 28, 0.88) 100%
  );
  border-radius: 30px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  color: white;
  font-family: 'Inter', sans-serif;
}

/* Pulsanti del modale */
.modal-buttons {
  display: flex;
  justify-content: center; /* Centra i pulsanti */
  gap: 20px;              /* Distanza orizzontale tra i pulsanti */
  margin-top: 20px;
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
}

.confirm-button {
  background-color: white;
  color: #df4634;
  border: 2px solid white;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.confirm-button:hover {
  background-color: #f0f0f0;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

/* Messaggi di errore e successo */
.error-message {
  color: #ffcccc;
  margin-top: 10px;
  font-size: 0.9em;
}

.success-message {
  color: #ccffcc;
  margin-top: 10px;
  font-size: 0.9em;
}

/* ------------------------------------------ */
/*    CLASSE PER BLOCCARE LO SCROLL DEL BODY */
/* ------------------------------------------ */
.no-scroll {
  overflow: hidden;
}
