/* File: src/components/styles/Presentation.css */

/* Container delle immagini */
.image-container {
  position: relative;
  margin-top: 3rem;
  width: 70vw;
  max-width: 1100px;
  height: auto;
  aspect-ratio: 16/9;
}

/* Video di sfondo */
.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.875rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Immagine dell'insegnante */
.teacher-image {
  width: 50% !important;
  height: auto;
  position: absolute;
  top: 10%;
  left: -20%;
  z-index: 2;
  object-fit: contain;
}

/* Media Queries per Preferenze di Riduzione delle Animazioni */
@media (prefers-reduced-motion: reduce) {
  .teacher-image {
    opacity: 1;
    transform: none;
  }
}
