.game-details-container {
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  max-width: 1200px;
  margin: 0 auto;
  color: #1a1a1a;
}

/* HEADER E BOTTONI ------------------------------------------------ */
.game-details-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 1.5rem;
  position: relative;
}

.game-details-header h2 {
  margin: 1rem 0 0.75rem;
  font-weight: 700;
  font-size: 2rem;
  color: #1a1a1a;
  letter-spacing: -0.5px;
}

.game-type {
  font-size: 0.875rem;
  font-weight: 500;
  color: #0369a1;
  background-color: #f0f9ff;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.back-button {
  padding: 0.75rem 1.25rem;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.875rem;
  color: #1a1a1a;
  font-weight: 600;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.back-button:hover {
  background-color: #f1f5f9;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.toggle-solutions-button {
  padding: 0.75rem 1.25rem;
  background-color: #0ea5e9;
  border: none;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  top: 1rem;
  right: 0;
}

.toggle-solutions-button:hover {
  background-color: #0284c7;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(14, 165, 233, 0.15);
}

.toggle-solutions-button.showing {
  background-color: #059669;
}

.toggle-solutions-button.showing:hover {
  background-color: #047857;
}

/* CERCHI PUNTEGGIO ------------------------------------------------ */
.score-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 2rem;
  position: relative;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.score-circle::after {
  content: '';
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  border: 2px solid transparent;
  background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0.3)) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.score-high {
  background: linear-gradient(135deg, #34d399 0%, #059669 100%);
}

.score-medium {
  background: linear-gradient(135deg, #fbbf24 0%, #d97706 100%);
}

.score-low {
  background: linear-gradient(135deg, #f87171 0%, #dc2626 100%);
}

/* QUIZ / STATS / CARDS -------------------------------------------- */
.quiz-stats,
.fillthebox-stats,
.matchpairs-stats,
.rearrange-stats,
.selectgroup-stats,
.crossword-stats,
.scrittura-stats,
.game-stats {
  display: flex;
  margin-bottom: 2.5rem;
  align-items: center;
  background-color: #fafafa;
  padding: 2rem;
  border-radius: 16px;
  border: 1px solid #f0f0f0;
}

.quiz-stats-info,
.fillthebox-stats-info,
.matchpairs-stats-info,
.rearrange-stats-info,
.selectgroup-stats-info,
.crossword-stats-info,
.scrittura-stats-info,
.game-completion {
  flex-grow: 1;
}

/* RISULTATI DOMANDE, ESERCIZI ECC. -------------------------------- */
.question-result,
.fillthebox-result,
.matchpairs-result {
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.question-result:hover,
.fillthebox-result:hover,
.matchpairs-result:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
}

.question-result.correct,
.fillthebox-result.correct,
.matchpairs-result.correct {
  background-color: #f0fdf4;
  border-left: 4px solid #22c55e;
}

.question-result.incorrect,
.fillthebox-result.incorrect,
.matchpairs-result.incorrect {
  background-color: #fef2f2;
  border-left: 4px solid #ef4444;
}

.question-result.open-ended {
  background-color: #f0f9ff;
  border-left: 4px solid #0ea5e9;
}

.question-text,
.fillthebox-exercise {
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  color: #1a1a1a;
  line-height: 1.6;
}

.question-details,
.fillthebox-details,
.matchpairs-assignment {
  padding-left: 15px;
  border-left: 2px solid #eaeaea;
  margin-bottom: 15px;
  color: #555;
  line-height: 1.5;
}

.answer-text {
  margin-bottom: 10px;
  color: #444;
}

.correct-answer-text {
  color: #388E3C;
  font-weight: 500;
}

/* CRUCIVERBA, RIORDINO, GRUPPI ------------------------------------ */
.crossword-section,
.rearrange-section,
.selectgroup-section {
  margin-bottom: 40px;
  background-color: #FAFBFD;
  padding: 20px;
  border-radius: 10px;
}

.crossword-words-grid,
.rearrange-exercises-grid,
.selectgroup-groups-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.crossword-word-card,
.rearrange-exercise-card,
.selectgroup-group-card {
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.crossword-word-card:hover,
.rearrange-exercise-card:hover,
.selectgroup-group-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
}

.crossword-word-card.correct,
.rearrange-exercise-card.correct,
.selectgroup-group-card.correct {
  border-top: 3px solid #4CAF50;
}

.crossword-word-card.incorrect,
.rearrange-exercise-card.incorrect,
.selectgroup-group-card.incorrect {
  border-top: 3px solid #F44336;
}

.word-card-header,
.exercise-card-header,
.group-card-header {
  padding: 15px;
  background-color: #F9FAFB;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  position: relative;
}

.word-number {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.85rem;
  margin-right: 12px;
  background-color: #1976D2; /* Numero di partenza */
  color: #FFF;
  font-weight: bold;
}

.crossword-word-card.correct .word-number {
  background-color: #4CAF50;
}

.word-clue,
.exercise-card-header h6,
.group-card-header h6 {
  margin: 0;
  font-size: 0.95rem;
  font-weight: 600;
  flex-grow: 1;
  color: #333;
}

.word-status-badge,
.exercise-status-badge,
.group-status-badge {
  padding: 0.375rem 0.75rem;
  border-radius: 8px;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.word-status-badge.correct,
.exercise-status-badge.correct,
.group-status-badge.correct {
  background-color: #f0fdf4;
  color: #059669;
}

.word-status-badge.incorrect,
.exercise-status-badge.incorrect,
.group-status-badge.incorrect {
  background-color: #fef2f2;
  color: #dc2626;
}

.word-card-content,
.exercise-card-content,
.group-card-content {
  padding: 18px;
}

.game-not-completed {
  padding: 40px 30px;
  text-align: center;
  background-color: #FAFBFD;
  border-radius: 10px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.06);
}

.back-to-game-button {
  padding: 12px 24px;
  background-color: #1976D2;
  color: #FFF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 500;
  font-size: 0.95rem;
  transition: all 0.3s ease;
}

.back-to-game-button:hover {
  background-color: #1565C0;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* TITOLI ---------------------------------------------------------- */
.quiz-details h3,
.fillthebox-details h3,
.matchpairs-details h3,
.rearrange-details h3,
.selectgroup-details h3,
.crossword-details h3,
.scrittura-details h3,
.generic-game-details h3 {
  color: #1a1a1a;
  margin: 2.5rem 0 1.5rem;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: -0.5px;
}

.quiz-details h4,
.fillthebox-details h4,
.matchpairs-details h4,
.rearrange-details h4,
.selectgroup-details h4,
.crossword-details h4 {
  margin: 25px 0 15px;
  color: #444;
  padding-bottom: 8px;
  border-bottom: 1px solid #eaeaea;
  font-weight: 600;
  font-size: 1.2rem;
}

/* RESPONSIVE ------------------------------------------------------- */
@media (max-width: 768px) {
  .game-details-container {
    padding: 1.5rem;
  }

  .game-details-header h2 {
    font-size: 1.75rem;
  }

  .toggle-solutions-button {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 1rem;
    width: 100%;
  }

  .quiz-stats,
  .fillthebox-stats,
  .matchpairs-stats,
  .rearrange-stats,
  .selectgroup-stats,
  .crossword-stats,
  .scrittura-stats,
  .game-stats {
    flex-direction: column;
    text-align: center;
    padding: 1.5rem;
  }

  .score-circle {
    margin: 0 auto 1.5rem;
  }

  .crossword-words-grid,
  .rearrange-exercises-grid,
  .selectgroup-groups-grid {
    grid-template-columns: 1fr;
  }
}

.quiz-score-details {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.open-ended-note {
  font-style: italic;
  font-size: 0.9rem;
  color: #666;
}

.quiz-completion-time {
  font-size: 0.9rem;
  color: #666;
}
