/* Contenitore principale */
.profile-documents {
  background: linear-gradient(180deg, #DF4634 0%, #000000 100%);
  border-radius: 1.875rem; /* 30px */
  box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.1); /* 0px 4px 10px */
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem; /* 20px */
  padding: 1.25rem; /* 20px */
  max-height: 100vh; /* Rende il contenitore responsivo all'altezza della finestra */
  overflow-y: auto; /* Abilita lo scorrimento in verticale */
  box-sizing: border-box; /* Include il padding nel calcolo dell'altezza */
}

/* Titolo "Documenti" */
.documents-title {
  font-size: 2rem !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 300 !important;
  align-self: flex-start;
  margin-top: 15% !important;
  margin-bottom: 1rem; /* Spazio inferiore per separare il titolo dal contenuto */
}

/* Contenitore per centrare la griglia e abilitare lo scorrimento */
.categories-container {
  display: flex;
  justify-content: center; /* Centra orizzontalmente */
  align-items: flex-start; /* Allinea verticalmente all'inizio */
  flex: 1;
  max-height: calc(100vh - 4rem); /* Altezza massima basata sulla finestra meno spazio per il titolo */
  overflow-y: auto; /* Abilita lo scorrimento verticale */
  padding-bottom: 1rem; /* Aggiunto padding in basso */
}

/* Griglia di categorie */
.categories-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Due colonne fisse */
  padding: 0px 2rem;
  row-gap: 0rem;
  width: 100%;
  max-width: 30rem; /* Limita la larghezza massima per mantenere la centratura */
}

/* Contenitore singola categoria */
.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Per posizionare lo spinner */
  height: 12rem; /* Altezza fissa, ad esempio 192px */
}

/* Singola categoria (div rossa) */
.document-category {
  width: 7rem; /* 112px */
  height: 7rem; /* 112px */
  background: #DF4634;
  border-radius: 1.875rem; /* 30px */
  box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25); /* 0px 4px 4px */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s, border 0.2s;
  flex-shrink: 0; /* Impedisce all'elemento di ridursi */
}

.document-category.drag-active {
  background: #c13a2d; /* Colore di sfondo durante il drag */
  border: 2px solid #ffffff;
}

.document-category:hover {
  transform: scale(1.05);
}

/* Icona "add_file.png" */
.add-file-icon {
  width: 3rem; /* 48px */
  height: 3rem; /* 48px */
  object-fit: contain;
}

/* Titolo della categoria */
.category-title {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 0.3125rem; /* 5px */
  height: 2.5rem; /* Altezza fissa per due righe */
  overflow: hidden; /* Nasconde il testo in eccesso */
  text-overflow: ellipsis; /* Aggiunge "..." alla fine del testo */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limita a 2 linee */
  -webkit-box-orient: vertical;
}

/* Prompt di upload */
.upload-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Documento esistente */
.existing-document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.document-link {
  color: #1976d2;
  text-decoration: none;
  margin-top: 0.3125rem; /* 5px */
}

.document-link:hover {
  text-decoration: underline;
}

/* Spinner di caricamento */
.uploading-spinner {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

/* Messaggi di successo */
.success-message {
  color: #4caf50;
  font-size: 0.875rem; /* 14px */
  margin-top: 0.5rem;
  text-align: center;
}

/* Messaggi di errore */
.error-message {
  color: #f44336;
  font-size: 0.875rem; /* 14px */
  margin-top: 0.5rem;
  text-align: center;
}
