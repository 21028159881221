/* src/components/Profile/styles/Profile.css */

/* Contenitore del profilo */
.container-profile {
  max-width: 1600px;
  margin: 0 auto;
}

.profile-root {
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  min-height: 100vh;
  background-color: #f44336;
}

.profile-content {
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin-top: -2%;
  gap: 40px;
}

/* Container per ProfileDetails e ProfileDocuments */
.profile-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  gap: 40px;
  width: 85%;
  height: 70%;
}

.profile-box,
.profile-documents {
  flex: 1;
  max-width: 500px;
  min-width: 300px;
  height: 100%;
  border-radius: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Media Query per Responsività */
@media (max-width: 800px) {
  .profile-content {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .profile-sections {
    flex-direction: column;
    width: 100%;
  }

  .profile-box,
  .profile-documents {
    flex: none;
    width: 60%;
    align-self: center;
    max-width: none;
  }

  .profile-documents {
    margin-top: 20px;
  }
}
