/* src/components/styles/VideoComponent.css */

/* Container del video */
.video-container {
    position: relative;
    width: 70%;
    height: auto;
    aspect-ratio: 16 / 9;
    border-radius: 1.875rem;
    overflow: hidden; /* Assicura che il video non esca dal contenitore */
    margin-top: 0rem; /* Aggiungi margine superiore per separarlo dal testo */
  }
  
  /* Video di sfondo */
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Assicura che il video copra l'intero contenitore */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  /* Stile personalizzato per Signup (opzionale) */
  .signup-video {
    width: 320px; /* Imposta la larghezza desiderata */
    height: auto;
    aspect-ratio: auto; /* Permette all'altezza di adattarsi automaticamente */
  }
  
  /* Media Queries per Preferenze di Riduzione delle Animazioni */
  @media (prefers-reduced-motion: reduce) {
    .background-video {
      /* Nessuna animazione da rimuovere per il video */
    }
  }
  