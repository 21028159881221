/* Stili generali per TutorialPage */
/* ----- INVITO AL TUTORIAL LIVE ----- */
.live-tutorial-invite {
  text-align: center;
  margin-bottom: 3rem;
}

/* Testo sotto il titolo "Tutorial" */
.live-tutorial-invite p {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #2b2e34;
  font-weight: 600;
}

/* Stile del bottone "Iscriviti" */
.live-button {
  display: inline-block;
  text-decoration: none;
  padding: 0.6rem 1.2rem;
  background-color: #df4634;
  color: #fff;
  border-radius: 0.5rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.live-button:hover {
  background-color: #b33023;
}


.tutorial-page {
  font-family: 'Inter', sans-serif;
  color: #2b2e34;
  background-color: #fafafa; /* Leggero sfondo globale */
}

.container_tutorial {
  max-width: 1400px; /* Se vuoi ancora più larghezza, aumenta */
  margin: 0 auto;
  padding: 2rem;
}

.tutorial-section {
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  padding: 3rem;
}

/* Titolo principale */
.tutorial-section h1 {
  text-align: center;
  font-size: clamp(30px, 6vw, 65px);
  font-weight: 900;
  line-height: clamp(26px, 6vw, 80px);
  background: linear-gradient(180deg, #df4634, #000000);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 1rem;
}

/* Testo introduttivo */
.tutorial-section p {
  text-align: center;
  font-size: clamp(16px, 2vw, 24px);
  font-weight: 500;
  line-height: clamp(18px, 3vw, 32px);
  margin-bottom: 3rem;
  color: #2b2e34;
}

/* Layout delle righe tutorial */
.tutorial-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 4rem; /* Spazio tra una sezione e l'altra */
}

/* Inverte ordine se index è dispari (reverse) */
.tutorial-row.reverse {
  flex-direction: row-reverse;
}

/* TESTO: 30% della larghezza */
.tutorial-row-text {
  flex: 0 0 30%;
}

/* VIDEO: 70% della larghezza */
.tutorial-row-video {
  flex: 0 0 70%;
  display: flex;
  justify-content: center;
}

/* Titoli e descrizioni del tutorial */
.tutorial-row-text h2 {
  font-size: clamp(24px, 4vw, 36px);
  font-weight: 700;
  margin-bottom: 1rem;
}

.tutorial-row-text p {
  font-size: clamp(14px, 1.8vw, 18px);
  line-height: 1.5;
  color: #555;
}

/* Responsivo (tablet e mobile) */
@media (max-width: 992px) {
  .tutorial-row {
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .tutorial-row.reverse {
    flex-direction: column;
  }
  .tutorial-row-text,
  .tutorial-row-video {
    flex: 1 1 auto;
    max-width: 100%;
  }
}

/* Responsivo (schermi piccoli) */
@media (max-width: 480px) {
  .tutorial-section {
    padding: 1.5rem;
  }
  .tutorial-row-text h2 {
    font-size: clamp(20px, 6vw, 28px);
  }
  .tutorial-row-text p {
    font-size: clamp(14px, 4vw, 18px);
  }
}
