.floating-menu {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 5px 20px;
  border-radius: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  align-items: center;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.floating-menu:hover {
  background: linear-gradient(180deg, rgba(223, 70, 52, 1) 0%, rgba(121, 38, 28, 1) 100%);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.floating-menu-nav {
  display: flex;
  align-items: center;
}

/* Voci di menu generiche */
.floating-menu-item {
  margin: 0 20px;
  color: #2b2e34;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  transition: color 0.3s ease, transform 0.3s ease;
}

.floating-menu-item:hover {
  color: white;
  transform: scale(1.1);
  text-shadow: 0px 4px 10px rgba(255, 255, 255, 0.5);
}

.floating-menu:hover .floating-menu-item {
  color: white;
}

.floating-menu.hidden {
  opacity: 0;
  transform: translateX(-50%) translateY(-20px); /* Slide up */
  pointer-events: none; /* Disabilita l'interazione quando nascosto */
}

.floating-menu.visible {
  opacity: 1;
  transform: translateX(-50%) translateY(0); /* Posizione originale */
  pointer-events: auto;
}

/* Dropdown Profilo */
.profile-menu {
  position: relative;
}

.profile-link {
  margin: 0 15px;
  color: white;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-link:hover {
  color: #ffdede;
}

/* Tendina profilo */
.profile-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background: linear-gradient(
    180deg,
    #df4634 0%,
    rgba(172, 54, 40, 0.98) 50%,
    rgba(121, 38, 28, 0.88) 100%
  );
  border-radius: 15px;
  padding: 10px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
}

.profile-item {
  padding: 10px;
  color: white;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
}

.profile-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(43, 46, 52, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
}

/* Contenuto modale */
.modal-content {
  background: linear-gradient(
    180deg,
    #df4634 0%,
    rgba(172, 54, 40, 0.98) 50%,
    rgba(121, 38, 28, 0.88) 100%
  );
  border-radius: 30px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  color: white;
  font-family: 'Inter', sans-serif;
  position: relative;
  z-index: 1003;
}

/* Pulsanti modale */
.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
}

.confirm-button {
  background-color: white;
  color: #df4634;
  border: 2px solid white;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.confirm-button:hover {
  background-color: #f0f0f0;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

.error-message {
  color: #ffcccc;
  margin-top: 10px;
  font-size: 0.9em;
}

.success-message {
  color: #ccffcc;
  margin-top: 10px;
  font-size: 0.9em;
}

/* Dropdown "Giochi" */
.games-menu {
  position: relative; /* Consente il posizionamento assoluto della .games-dropdown */
  display: inline-block;
  cursor: pointer;
}

.games-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1500;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  padding: 5px 0;
}

.games-item {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #2b2e34;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.games-item:hover {
  background-color: #df4634;
  color: #ffffff;
}

/* Nascondi il FloatingMenu su dispositivi mobili */
@media (max-width: 768px) {
  .floating-menu {
    display: none;
  }
}
