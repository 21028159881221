.game-wrapper {
  margin: 20px 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.game-progress-indicator {
  padding: 15px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #e0e0e0;
}

.game-progress-indicator p {
  margin: 0;
  font-weight: 500;
  color: #555;
  text-align: center;
}

.progress-bar {
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #2196F3;
  transition: width 0.5s ease;
}

.no-data-container,
.error-container,
.game-error,
.game-not-implemented {
  padding: 40px 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.no-data-container h2,
.error-container h2,
.game-error h2,
.game-not-implemented h2 {
  margin-bottom: 15px;
  color: #333;
}

.error-container p,
.game-error p,
.game-not-implemented p {
  color: #666;
  margin-bottom: 20px;
}

.error-details {
  color: #f44336;
  font-family: monospace;
  background-color: #ffebee;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 0.9rem;
}

.submit-final-container {
  padding: 20px;
  text-align: center;
  background-color: #f7f7f7;
  border-top: 1px solid #e0e0e0;
}

.submit-final-button {
  padding: 12px 24px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-final-button:hover {
  background-color: #388E3C;
}

@media (max-width: 768px) {
  .game-wrapper {
    margin: 15px 0;
  }
  
  .game-progress-indicator {
    padding: 12px;
  }
  
  .submit-final-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
} 