/* Stili per il modal di conferma reset */
.reset-confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.reset-confirm-modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 480px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  animation: slideIn 0.3s ease;
}

.reset-confirm-header {
  background-color: #df4634;
  color: white;
  padding: 1rem 1.5rem;
}

.reset-confirm-header h3 {
  margin: 0;
  font-size: 1.3rem;
}

.reset-confirm-content {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.reset-confirm-icon {
  flex-shrink: 0;
}

.reset-confirm-text {
  flex-grow: 1;
}

.reset-confirm-content p {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  line-height: 1.5;
}

.reset-confirm-warning {
  color: #e74c3c;
  font-weight: 500;
  margin-top: 1rem;
}

.reset-confirm-actions {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
  background-color: #f8f8f8;
  border-top: 1px solid #eee;
  gap: 1rem;
}

.reset-confirm-button {
  padding: 0.7rem 1.2rem;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.reset-confirm-button.cancel {
  background-color: #f8f8f8;
  color: #666;
  border: 1px solid #ddd;
}

.reset-confirm-button.cancel:hover {
  background-color: #eee;
}

.reset-confirm-button.confirm {
  background-color: #e74c3c;
  color: white;
}

.reset-confirm-button.confirm:hover {
  background-color: #c0392b;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Stili per dispositivi mobili */
@media (max-width: 768px) {
  .reset-confirm-modal {
    width: 95%;
    max-width: 350px;
  }
  
  .reset-confirm-header h3 {
    font-size: 1.1rem;
  }
  
  .reset-confirm-content {
    padding: 1.2rem;
    flex-direction: column;
    text-align: center;
  }
  
  .reset-confirm-content p {
    font-size: 1rem;
  }
  
  .reset-confirm-actions {
    padding: 0.8rem 1.2rem;
  }
  
  .reset-confirm-button {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }
} 