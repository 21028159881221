/* Stili generali dell'header */
.header {
  position: relative; /* Assicura il corretto stacking dei figli */
  height: 80px; /* Altezza fissa per l'header */
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

/* Container style */
.container {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo container */
.logo-container {
  display: flex;
  align-items: center;
}

/* Logo text */
.logo-text {
  font-size: 3vw;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 50px;
  margin-left: -20px;
  margin-bottom: 1%;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Logo image */
.logo-image {
  width: 7.5%;
  height: 7.5%;
  margin-left: -1%;
  margin-bottom: 0.5%;
}

.logo-image-white {
  width: 7%;
  margin-left: -1%;
  height: auto;
}

/* Pulsante di join */
.minerva-join-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.5rem;
  height: 2.5rem;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  border: 1px #df4634 solid;
  text-decoration: none;
  cursor: pointer;
  color: #2b2e34;
  font-size: clamp(16px, 3vw, 20px);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: clamp(24px, 4vw, 40px);
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.minerva-join-button:hover {
  transform: scale(1.05);
  background-color: #df4634;
  color: white;
}

/* Pulsante Chat AI */
.ai-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background: #df4634;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.ai-button svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.ai-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.15);
  transition: all 0.75s ease;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.ai-button:hover {
  transform: scale(1.05);
  background-color: #c43e2e;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}

.ai-button:hover::after {
  transform: translate(-50%, -50%) scale(1);
  opacity: 0;
}

.ai-button:active {
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .ai-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* Icona hamburger per il menu mobile */
.hamburger-icon {
  display: none;
}

.hamburger-icon img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Menu di navigazione */
.menu {
  display: flex;
  align-items: center;
  gap: 0px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  overflow: visible;
}

.menu a {
  font-size: clamp(10px, 2vw, 20px);
  font-weight: 300;
  line-height: 30px;
  margin-left: 0px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease;
  padding: 10px 15px;
}

.menu a:hover {
  transform: scale(1.05);
}

/* User actions */
.user-actions {
  display: flex;
  align-items: center;
  gap: 0px;
}

.login-link {
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  text-decoration: none;
}

/* Divider tra gli elementi utente */
.divider {
  width: 1px;
  height: 35px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background-color: #2b2e34;
}

/* Profile link styles */
.profile-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5vw;
  height: 3.5vw;
  border-radius: 50%;
  background-color: #000000;
  text-decoration: none;
  cursor: pointer;
  margin-left: -5% !important;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #df4634;
}

/* Profile dropdown styles */
.profile-menu {
  position: relative;
}

.profile-dropdown {
  position: absolute;
  top: 60px;
  right: 0;
  width: 250px;
  background: linear-gradient(180deg, #df4634 0%, rgba(172, 54, 40, 0.98) 50%, rgba(121, 38, 28, 0.88) 100%);
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  z-index: 1500; /* Z-index aumentato per sovrapporsi al contenuto della pagina */
  display: flex;
  flex-direction: column;
}

.profile-item {
  display: flex;
  align-items: center;
  padding: 10px;
  color: white;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  transition: background-color 0.3s ease;
}

.profile-item-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.profile-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  cursor: pointer;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 46, 52, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

/* Modal content */
.modal-content {
  background: linear-gradient(180deg, #df4634 0%, rgba(172, 54, 40, 0.98) 50%, rgba(121, 38, 28, 0.88) 100%);
  border-radius: 30px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  color: white;
  font-family: 'Inter', sans-serif;
}

/* Modal buttons */
.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
}

.confirm-button {
  background-color: white;
  color: #DF4634;
  border: 2px solid white;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.confirm-button:hover {
  background-color: #f0f0f0;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

/* Messaggi di errore e successo */
.error-message {
  color: #ffcccc;
  margin-top: 10px;
  font-size: 0.9em;
}

.success-message {
  color: #ccffcc;
  margin-top: 10px;
  font-size: 0.9em;
}

/* Dropdown per i giochi */
.games-menu {
  position: relative;
  display: inline-block;
}

.games-menu span {
  font-size: clamp(10px, 2vw, 20px);
  font-weight: 300;
  line-height: 30px;
  padding: 10px 15px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease;
}

.games-menu span:hover {
  transform: scale(1.05);
}

.games-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1500; /* Z-index aumentato per farlo apparire in primo piano */
  border-radius: 5px;
  overflow: hidden;
}

.games-dropdown .games-item {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #2b2e34;
  transition: background-color 0.3s ease;
}

.games-dropdown .games-item:hover {
  background-color: #df4634;
  color: white;
}

/* Media Queries */
@media (max-width: 768px) {
  .menu,
  .user-actions {
    display: none;
  }

  .logo-container {
    justify-content: flex-start;
    margin-right: auto;
  }

  .hamburger-icon {
    display: block;
    position: fixed;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 5000;
  }

  .logo-image {
    width: 15%;
    height: 15%;
    margin-left: -2%;
    margin-bottom: 0.8%;
  }

  .logo-text {
    font-size: 6vw;
  }
}

/* Stili per il bottone "Crea Gioco" */
.crea-gioco-button {
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  border: none;
  border-radius: 15px;
  background-color: #df4634;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.crea-gioco-button:hover {
  transform: scale(1.05);
  background-color: #c43e2e;
}
