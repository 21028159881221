/* Contenitore del video */
.video-container {
  position: relative;
  width: 100%;
  /* Se vuoi porre un limite massimo, decommenta la riga sotto */
  /* max-width: 1200px; */
  /* Rimuoviamo l'aspect-ratio fisso per far sì che il video mantenga le sue proporzioni */
  /* aspect-ratio: 16 / 9; */
  
  border-radius: 1.25rem;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
}

/* Stile del video: 
   - "width: 100%" lo fa adattare in larghezza al contenitore
   - "height: auto" mantiene le proporzioni verticali/orizzontali
   - "object-fit: contain" evita tagli e zoom eccessivi */
.background-video {
  width: 100%;
  height: auto;
  object-fit: contain; 
  display: block;
}

/* Per gli iframe di Google Drive */
iframe.background-video {
  aspect-ratio: 16 / 9;
  min-height: 300px;
}

/* Stile specifico per il video verticale */
video[src*="Minerva_Tutorial_personalizzazione_risorse.mp4"].background-video {
  display: block;
  margin: 0 auto;
  width: auto !important;
  max-width: 400px !important;
  height: auto !important;
  object-fit: contain !important;
  max-height: none !important;
}

/* Overlay per indicare che il video è cliccabile */
.video-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.video-container:hover .video-overlay {
  opacity: 1;
}

.expand-icon {
  font-size: 16px;
}

/* Stili per la modalità a schermo intero */
.fullscreen-video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.fullscreen-video-container {
  position: relative;
  width: 90%;
  max-width: 1200px;
  text-align: center;
}

.fullscreen-video {
  width: 100%;
  height: auto;
  max-height: 90vh;
  object-fit: contain;
  margin: 0 auto;
}

/* Per video verticali in modalità fullscreen */
video[src*="Minerva_Tutorial_personalizzazione_risorse.mp4"].fullscreen-video {
  max-width: 500px;
  max-height: 80vh;
  margin: 0 auto;
  height: auto;
}

/* Per gli iframe di Google Drive in modalità fullscreen */
iframe.fullscreen-video {
  width: 100%;
  height: 80vh;
}

.close-fullscreen {
  position: absolute;
  top: -40px;
  right: 0;
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}

/* Se usi la classe "tutorial-row-video" per il contenitore esterno del componente,
   assicurati che non forzi altezza e abbia overflow visibile. */
.tutorial-row-video {
  flex: 0 0 70%;
  display: flex;
  justify-content: center;
  overflow: visible;
}

/* Media query per tablet e mobile */
@media (max-width: 768px) {
  .tutorial-row {
    flex-direction: column; /* Metti testo e video uno sotto l'altro */
  }

  .tutorial-row-text,
  .tutorial-row-video {
    flex: auto;
    width: 100%;
    max-width: 100%;
  }
  
  .fullscreen-video-container {
    width: 95%;
  }
  
  /* Se vuoi reintrodurre un'altezza minima su mobile per non avere video troppo schiacciati:
     .video-container {
       min-height: 220px; 
     } 
  */
}
