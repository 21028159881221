/* File: src/components/styles/Subscription.css */

/* Contenitore principale */
.subscription-container {
  font-family: 'Inter';
  min-height: 100vh;
  background: linear-gradient(180deg, #DF4634 36%, #70231A 67.5%, #000000 100%);
  box-sizing: border-box;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subscription-title {
  text-align: center;
  margin-bottom: 2rem;
  color: #FFFFFF;
  font-weight: 800;
  font-size: clamp(1.8rem, 4vw, 3rem);
  margin-top: 1rem;
}

/* Contenitore principale del contenuto */
.subscription-content-container {
  background-color: #FFFFFF;
  border-radius: 1.875rem;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
  margin: 0 auto;
  max-width: 95%;
  width: 100%;
  box-sizing: border-box;
}

/* Layout responsive */
@media (min-width: 720px) {
  .subscription-content-container {
    flex-direction: row;
    max-width: 1000px;
    align-items: flex-start;
  }
}

/* Colonna sinistra: Selettore piani + SubscriptionPlan + immagine */
.subscription-left-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

/* Gruppo di bottoni per i piani */
.subscription-button-group {
  background: transparent;
  box-shadow: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Immagine eventuale (es. Carta Docente) */
.subscription-image-container {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.subscription-image {
  max-width: 50%;
  height: auto;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  transition: transform 0.3s ease;
}
.subscription-image:hover {
  transform: scale(1.02);
}

/* Colonna destra: Lista features + immagine annual */
.subscription-right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

/* Titolo "Questo piano include" */
.subscription-features-title {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #1D1F23;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 1.25rem;
}

/* Lista e singoli elementi */
.subscription-features-list {
  list-style-type: none;
  padding: 0;
}
.subscription-feature-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: -0.5rem;
}
.subscription-feature-icon {
  min-width: 36px;
}
.subscription-feature-text {
  color: #2B2E34;
  font-size: 0.9rem;
  line-height: 1.4rem;
}

/* Immagini bonus piano annuale */
.subscription-annual-image-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 180px;
  pointer-events: none;
  display: none; /* di default nascondiamo su schermi piccoli */
  z-index: 1;
}

/* Immagine "Black Friday" */
.subscription-blackfriday-image {
  width: 160%;
  height: auto;
  border-radius: 0.5rem;
  margin-top: -8rem;
  margin-left: 2.5rem;
  transform: rotate(10deg);
}

/* Copertina "Quadagenda" */
.subscription-annual-image {
  width: 80%;
  height: auto;
  border-radius: 0.5rem;
  margin-top: 6rem;
  margin-left: 3rem;
  transform: rotate(10deg);
}

@media (min-width: 900px) {
  .subscription-annual-image-container {
    display: block;
  }
}

/* Dialog Pagamento */
.subscription-dialog-paper {
  border-radius: 1.875rem;
  padding: 1.5rem;
}
.subscription-dialog-title {
  color: #2B2E34;
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
}
.subscription-dialog-actions {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.subscription-dialog-button {
  background-color: #2B2E34;
  color: #FFFFFF;
  border-radius: 1.5rem;
  padding: 0.75rem 2rem;
  text-transform: none;
  font-size: 1rem;
  width: 100%;
  max-width: 300px;
  transition: background-color 0.3s ease;
}
.subscription-dialog-button:hover {
  background-color: #1e2229;
}
.subscription-dialog-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subscription-dialog-submit-button {
  margin-top: 16px;
  background-color: #2B2E34;
  color: #FFFFFF;
  border-radius: 1.5rem;
  padding: 0.75rem 2rem;
  text-transform: none;
  font-size: 1rem;
  max-width: 300px;
}
.subscription-dialog-submit-button:hover {
  background-color: #1e2229;
}
.subscription-dialog-back-button {
  margin-top: 16px;
}
.subscription-dialog-error {
  margin-top: 16px;
  text-align: center;
}

/* Overlay per il loading */
.loading-container {
  font-family: 'Inter';
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #DF4634 36%, #70231A 67.5%, #000 100%);
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Alert di errore e successo */
.error-alert,
.success-alert {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
}
