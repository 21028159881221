.interactive-game-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('../../../../public/verifiche_interattive/back_ground_verifiche_2_green.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.game-content {
  flex: 1;
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.game-container {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.game-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.game-header h1 {
  font-size: 2.5rem;
  color: white;
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  margin: 0;
}

.share-button {
  padding: 0.6rem 1.2rem;
  background-color: #19696A;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s;
  position: relative;
}

.share-button:hover {
  background-color: #135758;
}

.share-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
  filter: brightness(0) invert(1);
}

.share-feedback {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  white-space: nowrap;
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translate(-50%, 10px); }
  to { opacity: 1; transform: translate(-50%, 0); }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #19696A;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.game-area {
  min-height: 400px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.error-container,
.no-data-container {
  text-align: center;
  padding: 3rem 1rem;
}

.error-container h2,
.no-data-container h2 {
  color: #df4634;
  margin-bottom: 1rem;
}

.error-container button,
.no-data-container button {
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #19696A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.error-container button:hover,
.no-data-container button:hover {
  background-color: #135758;
}

/* Stili per dispositivi mobili */
@media (max-width: 768px) {
  .game-container {
    padding: 1rem;
  }
  
  .game-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .game-header h1 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  
  .game-area {
    min-height: 300px;
    padding: 1rem;
  }
}
