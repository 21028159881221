/* ====================================== */
/*        FillTheBox GAME STYLES         */
/* ====================================== */

/* Struttura principale del gioco */
.fillthebox-game {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0.5rem;
  position: relative;
  overflow-anchor: none; /* Previene il riposizionamento automatico durante lo scroll */
  contain: layout; /* Isola il layout dell'elemento per migliorare la stabilità */
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

/* Stile per le istruzioni */
.fillthebox-instructions {
  text-align: center;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #fff8f8 0%, #f9f5f5 100%);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 10px 25px rgba(223, 70, 52, 0.08);
  border-left: 4px solid #DF4634;
  animation: fadeIn 0.6s ease-out;
  position: relative;
  overflow: hidden;
  will-change: transform; /* Ottimizza per animazioni */
  transform: translateZ(0); /* Force hardware acceleration */
}

.fillthebox-instructions::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, transparent 50%, rgba(223, 70, 52, 0.1) 50%);
  border-radius: 0 0 0 60px;
  z-index: 0;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.fillthebox-instructions h3 {
  font-size: 1.4rem;
  color: #333;
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.fillthebox-instructions h3 svg {
  stroke: #DF4634;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.fillthebox-subtitle {
  margin: 0.8rem 0 0;
  font-size: 0.95rem;
  color: #666;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

/* Barra di progresso */
.fillthebox-progress {
  margin-top: 1.2rem;
  text-align: center;
  position: relative;
  z-index: 1;
}

.fillthebox-progress p {
  color: #555;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.fillthebox-progress-bar {
  height: 8px;
  background-color: rgba(223, 70, 52, 0.1);
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
  width: 80%;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.fillthebox-progress-bar-fill {
  height: 100%;
  background: linear-gradient(90deg, #DF4634, #FF7863);
  border-radius: 20px;
  transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 0 5px rgba(223, 70, 52, 0.3);
}

.fillthebox-exercise {
  background-color: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-bottom: 2rem;
  transform: translateY(0);
  will-change: transform; /* Ottimizza per animazioni */
  z-index: 2; /* Assicura che sia sopra altri elementi */
  backface-visibility: hidden; /* Migliora le performance */
  transform-style: preserve-3d; /* Previene flickering */
  contain: content;
}

.fillthebox-exercise:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
}

.fillthebox-exercise:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(90deg, #DF4634, #ff7e7e);
  border-radius: 4px 4px 0 0;
}

.fillthebox-exercise h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1.2rem;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 0 auto 1.8rem;
  padding-bottom: 0.8rem;
  font-weight: 600;
  width: 100%;
}

.fillthebox-exercise h3:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #DF4634;
  border-radius: 3px;
}

/* Contenitore della frase */
.fillthebox-sentence-container {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 1.8rem;
  margin: 1.5rem 0;
  box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.04);
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(223, 70, 52, 0.08);
  contain: content; /* Isola il contenuto per prevenire riflessi */
  transform: translateZ(0);
  backface-visibility: hidden;
}

.fillthebox-sentence-container:hover {
  background-color: #f5f5f5;
}

.fillthebox-sentence {
  font-size: 1.3rem;
  line-height: 1.8;
  margin: 0;
  color: #333;
  text-align: center;
  font-weight: 500;
}

/* Spazi vuoti da riempire */
.fillthebox-blank {
  display: inline-block;
  padding: 0.4rem 1.2rem;
  margin: 0 0.3rem;
  min-width: 110px;
  text-align: center;
  background-color: rgba(223, 70, 52, 0.05);
  border: 2px dashed rgba(223, 70, 52, 0.3);
  color: #666;
  font-weight: 600;
  border-radius: 30px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  vertical-align: middle;
  position: relative; /* Stabilizza la posizione */
  z-index: 1; /* Assicura che sia sopra altri elementi */
}

.fillthebox-blank.filled {
  background-color: rgba(223, 70, 52, 0.1);
  border: 2px solid #DF4634;
  color: #DF4634;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(223, 70, 52, 0.15);
}

/* Elenco delle opzioni */
.fillthebox-options-label {
  font-weight: 600;
  margin-bottom: 1.2rem;
  color: #555;
  display: flex;
  align-items: center;
  font-size: 1.05rem;
}

/* Contenitore delle opzioni */
.fillthebox-options {
  position: relative;
  z-index: 2;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  contain: layout;
  transform: translateZ(0);
  backface-visibility: hidden;
}

/* Griglia delle opzioni per il layout a griglia */
.fillthebox-options-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 10px;
  justify-content: center;
  position: relative;
  z-index: 2;
  min-height: 58px;
  margin: 0 auto;
  padding: 5px;
  transform: translateZ(0);
  backface-visibility: hidden;
  contain: content; /* Cambiato da 'layout' a 'content' per consentire lo scroll */
  transform-style: preserve-3d;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.7);
  max-height: none; /* Rimuove il limite di altezza */
  overflow: visible; /* Consente la visibilità degli elementi fuori dal contenitore */
}

/* Stile per ogni singola opzione */
.fillthebox-option {
  padding: 0.8rem 1.5rem;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  color: #444;
  font-weight: 500;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
  user-select: none;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 3px;
  transition: transform 180ms ease-out, box-shadow 180ms ease-out;
  transform-style: preserve-3d;
  contain: layout paint style;
  grid-area: auto / auto / span 1 / span 1;
  touch-action: manipulation;
  /* Assicuriamo che l'elemento rimanga stabile */
  will-change: transform;
}

.fillthebox-option::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(223, 70, 52, 0.5) 0%, rgba(223, 70, 52, 0.8) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
  border-radius: 30px;
  pointer-events: none; /* Assicura che i click passino attraverso */
}

.fillthebox-option:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(223, 70, 52, 0.15);
  border-color: #DF4634;
  z-index: 5;
}

.fillthebox-option:hover::after {
  opacity: 0.1;
}

.fillthebox-option:active {
  transform: translateY(-1px) scale(0.98);
  box-shadow: 0 2px 4px rgba(223, 70, 52, 0.1);
}

.fillthebox-option.selected {
  background-color: #DF4634;
  color: white;
  box-shadow: 0 4px 8px rgba(223, 70, 52, 0.2);
  transform: translateY(-3px);
  border-color: #DF4634;
  font-weight: 600;
  z-index: 10;
  position: relative;
}

.fillthebox-option.selected::after {
  opacity: 0.1;
}

/* Navigazione */
.fillthebox-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 1.2rem;
  position: relative; /* Stabilizza la posizione */
  z-index: 3; /* Assicura che i pulsanti siano sempre accessibili */
}

.fillthebox-nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.9rem 1.5rem;
  background-color: white;
  border: 1.5px solid #DF4634;
  color: #DF4634;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  min-width: 140px;
  font-size: 0.95rem;
  position: relative;
  overflow: hidden;
  user-select: none; /* Previene selezione del testo accidentale */
  transform: translateZ(0); /* Force hardware acceleration */
}

.fillthebox-nav-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #DF4634 0%, #ff7e7e 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
  border-radius: 30px;
}

.fillthebox-nav-button.prev {
  padding-left: 1.2rem;
}

.fillthebox-nav-button.next {
  padding-right: 1.2rem;
}

.fillthebox-nav-button svg {
  stroke: #DF4634;
  transition: all 0.3s ease;
}

.fillthebox-nav-button.prev svg {
  margin-right: 8px;
}

.fillthebox-nav-button.next svg {
  margin-left: 8px;
}

.fillthebox-nav-button:hover {
  background-color: #DF4634;
  color: white;
  box-shadow: 0 6px 12px rgba(223, 70, 52, 0.2);
  transform: translateY(-3px);
}

.fillthebox-nav-button:hover::after {
  opacity: 1;
}

.fillthebox-nav-button:hover svg {
  stroke: white;
}

.fillthebox-nav-button:active {
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(223, 70, 52, 0.15);
}

.fillthebox-nav-button:disabled {
  background-color: #f5f5f5;
  border-color: #e0e0e0;
  color: #aaa;
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}

.fillthebox-nav-button:disabled svg {
  stroke: #aaa;
}

.fillthebox-nav-button:disabled::after {
  opacity: 0;
}

/* Pulsanti */
.fillthebox-button {
  padding: 1rem 2.5rem;
  background: linear-gradient(135deg, #DF4634 0%, #FF7863 100%);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  align-self: center;
  min-width: 180px;
  font-weight: 600;
  box-shadow: 0 8px 15px rgba(223, 70, 52, 0.25);
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  user-select: none; /* Previene selezione del testo accidentale */
}

.fillthebox-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #C62828 0%, #DF4634 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
  border-radius: 30px;
}

.fillthebox-button:hover {
  box-shadow: 0 10px 20px rgba(223, 70, 52, 0.3);
  transform: translateY(-3px);
}

.fillthebox-button:hover::after {
  opacity: 1;
}

.fillthebox-button:active {
  transform: translateY(-1px);
  box-shadow: 0 6px 10px rgba(223, 70, 52, 0.2);
}

.fillthebox-button svg {
  margin-right: 8px;
}

.fillthebox-button:disabled {
  background: linear-gradient(135deg, #e0e0e0 0%, #f5f5f5 100%);
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
  color: #999;
}

.fillthebox-button:disabled::after {
  opacity: 0;
}

.fillthebox-button.submit-final {
  background: linear-gradient(135deg, #DF4634 0%, #FF7863 100%);
  padding: 1.1rem 2.5rem;
  font-size: 1.2rem;
  box-shadow: 0 8px 15px rgba(223, 70, 52, 0.3);
}

.fillthebox-button.submit-final:hover {
  box-shadow: 0 12px 25px rgba(223, 70, 52, 0.4);
}

.fillthebox-button.submit-final::after {
  background: linear-gradient(45deg, #B71C1C 0%, #C62828 100%);
}

/* Messaggi di avviso/errore */
.fillthebox-warning {
  color: #e67e22;
  text-align: center;
  font-size: 0.95rem;
  margin-top: 1.2rem;
  background-color: rgba(230, 126, 34, 0.1);
  border-radius: 30px;
  padding: 0.9rem 1.5rem;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 3px solid #e67e22;
  box-shadow: 0 3px 8px rgba(230, 126, 34, 0.1);
  animation: fadeInUp 0.5s ease-out;
  position: relative; /* Stabilizza la posizione */
  z-index: 3; /* Assicura che sia sopra altri elementi */
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.fillthebox-warning svg {
  margin-right: 8px;
  animation: pulse 2s infinite;
}

/* Responsività */
@media (max-width: 768px) {
  .fillthebox-game {
    padding: 0.5rem;
    width: 92%;
  }
  
  .fillthebox-exercise {
    padding: 1.5rem;
  }
  
  .fillthebox-sentence-container {
    padding: 1.2rem;
  }
  
  .fillthebox-sentence {
    font-size: 1.1rem;
  }
  
  /* Sostituiamo completamente la griglia con un layout flex per mobile */
  .fillthebox-options-grid {
    display: flex !important;
    flex-wrap: wrap !important;
    grid-template-columns: none !important;
    position: static !important;
    gap: 8px !important;
    padding: 10px !important;
    min-height: auto !important;
    height: auto !important;
    max-height: none !important;
    overflow: visible !important;
    justify-content: center !important;
    contain: none !important;
  }
  
  .fillthebox-option {
    flex: 0 0 calc(50% - 10px) !important;
    width: calc(50% - 10px) !important;
    height: auto !important;
    padding: 0.7rem 0.5rem !important;
    margin: 0 !important;
    position: static !important;
    transform: none !important;
    contain: none !important;
    min-height: 40px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    box-sizing: border-box !important;
    grid-area: auto !important;
    font-size: 0.95rem !important;
  }
  
  .fillthebox-instructions {
    padding: 1.2rem;
  }
  
  .fillthebox-instructions h3 {
    font-size: 1.2rem;
  }
  
  .fillthebox-progress {
    width: 90%;
    margin: 1.2rem auto 0;
  }
  
  .fillthebox-progress-bar {
    height: 8px;
    width: 85%;
  }
  
  .fillthebox-navigation {
    flex-direction: row;
    gap: 0.8rem;
    margin-top: 1.5rem;
  }
  
  .fillthebox-nav-button {
    padding: 0.7rem 1rem;
    font-size: 0.9rem;
    min-width: 100px;
    width: 48%;
  }
  
  .fillthebox-button {
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
    min-width: 150px;
  }
  
  /* Rimuove tutti i blocchi di layout su mobile */
  html.scrolling .fillthebox-options-grid,
  html.scrolling .fillthebox-option {
    contain: none !important;
    touch-action: auto !important;
    pointer-events: auto !important;
    position: static !important;
    transform: none !important;
    will-change: auto !important;
  }
}

/* Per mobile ottimizziamo ulteriormente */
@media (max-width: 480px) {
  .fillthebox-game {
    width: 95%;
  }

  .fillthebox-progress {
    width: 85%;
  }
  
  .fillthebox-progress-bar {
    height: 6px;
    width: 90%;
  }
  
  /* Manteniamo un layout ancora più semplice su schermi piccoli */
  .fillthebox-options-grid {
    padding: 8px !important;
    gap: 6px !important;
    margin-bottom: 15px !important;
  }
  
  .fillthebox-option {
    flex: 0 0 calc(50% - 8px) !important;
    width: calc(50% - 8px) !important;
    padding: 0.5rem 0.4rem !important;
    font-size: 0.85rem !important;
    border-radius: 16px !important;
    min-height: 36px !important;
  }
  
  .fillthebox-nav-button {
    padding: 0.6rem 0.8rem;
    font-size: 0.85rem;
    min-width: 90px;
  }
  
  .fillthebox-button {
    padding: 0.8rem 1.2rem;
    font-size: 0.95rem;
  }
}

/* Stile specifico quando le opzioni sono posizionate con JavaScript */
.fillthebox-option[style*="width"] {
  justify-self: stretch;
  align-self: stretch;
  width: auto !important;
  height: auto !important;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Stile per fissare il componente durante lo scroll */
html.scrolling .fillthebox-game {
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
  contain: layout;
}

html.scrolling .fillthebox-options-grid {
  contain: content; /* Cambiato da 'strict' per consentire lo scroll */
  touch-action: auto; /* Cambiato da 'none' per consentire lo scrolling con touch */
  pointer-events: auto; /* Cambiato da 'none' per consentire interazioni */
}

html.scrolling .fillthebox-option {
  contain: content; /* Cambiato da 'strict' */
}

/* ====================================== */
/*      RISULTATI DI FILLTHEBOX           */
/* ====================================== */

/* Stili mantenuti per retrocompatibilità, ma i risultati non vengono più mostrati */
.fillthebox-details h3 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.fillthebox-score {
  font-size: 1.2rem;
  color: #2980b9;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.fillthebox-results-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fillthebox-result {
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.fillthebox-result.correct {
  background-color: #e8f8f5;
  border-left: 4px solid #27ae60;
}

.fillthebox-result.incorrect {
  background-color: #fdedeb;
  border-left: 4px solid #e74c3c;
}
