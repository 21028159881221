/* ====================================== */
/*              QUIZ GAME STYLES          */
/* ====================================== */

.quiz-game {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Stili per il loader di transizione tra quiz */
.quiz-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  min-height: 300px;
}

.quiz-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #df4634;
  border-radius: 50%;
  animation: quiz-spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes quiz-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Nuovi stili per la progress bar */
.quiz-progress {
  margin-bottom: 0.75rem;
  position: relative;
  padding: 1.25rem 0 0.5rem 0;
}

.quiz-progress-bar {
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
}

.quiz-progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #df4634, #ff6b58);
  border-radius: 10px;
  transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  box-shadow: 0 1px 5px rgba(223, 70, 52, 0.3);
}

.quiz-progress-fill::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 10px;
}

.quiz-progress-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.95rem;
  color: #555;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.quiz-progress-text span.current {
  color: #df4634;
  font-weight: 600;
}

.quiz-progress-dots {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  transform: translateY(-50%);
}

.quiz-progress-dot {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 2px solid #ddd;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.quiz-progress-dot.active {
  background-color: #df4634;
  border-color: #df4634;
  transform: scale(1.1);
  box-shadow: 0 0 0 3px rgba(223, 70, 52, 0.2);
}

.quiz-progress-dot.completed {
  background-color: #df4634;
  border-color: #df4634;
}

.quiz-question {
  background-color: white;
  border-radius: 1rem;
  padding: 2.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  min-height: 300px;
  display: flex;
  flex-direction: column;
}

.quiz-question-title {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.quiz-options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: center;
  margin-bottom: 1rem;
}

.quiz-option {
  padding: 0.75rem 1.5rem;
  border: 2px solid #eaeaea;
  border-radius: 1.875rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 500;
  background-color: white;
  color: #333;
}

.quiz-option:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 8px rgba(0,0,0,0.15);
  border-color: #df4634;
  background-color: #fdf5f4;
}

.quiz-option.selected {
  background-color: #DF4634;
  color: white;
  border-color: #DF4634;
}

.quiz-option.previous-answer {
  background-color: #FFEBEE;
  border-color: #E53935;
  color: #E53935;
}

/* Domande a risposta aperta */
.quiz-open-answer textarea {
  width: 100%;
  padding: 1rem;
  border: 2px solid #eaeaea;
  border-radius: 8px;
  font-family: inherit;
  font-size: 1rem;
  resize: vertical;
  min-height: 120px;
  transition: all 0.2s ease;
}

.quiz-open-answer textarea:focus {
  outline: none;
  border-color: #df4634;
  box-shadow: 0 0 0 2px rgba(223, 70, 52, 0.1);
}

/* Navigazione all'interno del quiz */
.quiz-navigation {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
  padding-bottom: 1.5rem;
}

.quiz-button {
  padding: 0.75rem 1.5rem;
  border-radius: 1.875rem;
  font-weight: 500;
  text-transform: none;
  transition: all 0.2s ease;
  min-height: 44px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz-button:not(.secondary) {
  background-color: #df4634;
  color: white;
  border: none;
}

.quiz-button:not(.secondary):hover {
  background-color: #E57373;
}

.quiz-button.secondary {
  background-color: transparent;
  color: #df4634;
  border: 2px solid #df4634;
}

.quiz-button.secondary:hover {
  background-color: rgba(223, 70, 52, 0.06);
}

.quiz-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Risultati del quiz */
.quiz-results {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.quiz-score {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1.5rem 0;
}

.quiz-summary {
  margin: 2rem 0;
  text-align: left;
}

.quiz-summary-item {
  padding: 1rem;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-bottom: 1rem;
  background-color: #f9f9f9;
}

.quiz-summary-item.correct {
  border-left: 5px solid #2ecc71;
}

.quiz-summary-item.incorrect {
  border-left: 5px solid #e74c3c;
}

/* Domande a risposta aperta nei risultati */
.quiz-summary-item.open-ended {
  background-color: #f5f9fc;
  border-left: 4px solid #3498db;
}

/* ====================================== */
/*       DETTAGLI E RISULTATI QUIZ        */
/* ====================================== */

/* Titolo della sezione dettagli quiz */
.quiz-details h3 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

/* Punteggio principale nei dettagli del quiz */
.quiz-score-details {
  font-size: 1.2rem;
  color: #2980b9;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

/* Contenitore delle domande/risposte nel riepilogo del quiz */
.quiz-questions-results {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Struttura del singolo risultato di domanda */
.question-result {
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.question-result.correct {
  background-color: #e8f8f5;
  border-left: 4px solid #27ae60;
}

.question-result.incorrect {
  background-color: #fdedeb;
  border-left: 4px solid #e74c3c;
}

.question-result.open-ended {
  background-color: #f5f9fc;
  border-left: 4px solid #3498db;
}

/* Testo domanda, risposta data e risposta corretta */
.question-text,
.answer-text,
.correct-answer-text {
  margin-bottom: 0.5rem;
}

.answer-text {
  color: #2c3e50;
}

.correct-answer-text {
  color: #27ae60;
}

/* Responsive */
@media (max-width: 768px) {
  .quiz-game {
    gap: 1rem;
    width: 92%;
  }
  
  .quiz-question {
    padding: 1.5rem;
    min-height: 250px;
    border-radius: 0.75rem;
  }
  
  .quiz-question-title {
    font-size: 1.1rem;
    margin-bottom: 1.25rem;
  }
  
  .quiz-options {
    gap: 0.5rem;
  }
  
  .quiz-option {
    padding: 0.5rem 1rem;
    font-size: 0.95rem;
    width: 100%;
    text-align: center;
  }
  
  .quiz-navigation {
    flex-direction: row;
    margin-top: 0.75rem;
    justify-content: space-between;
  }
  
  .quiz-button {
    width: 48%;
    min-height: 44px;
    font-size: 0.95rem;
    min-width: 100px;
    padding: 0.5rem 1rem;
  }
  
  .quiz-progress {
    width: 90%;
    margin: 0 auto 0.75rem auto;
    padding: 1rem 0 0.5rem 0;
  }
  
  .quiz-progress-bar {
    height: 8px;
    margin-bottom: 0.75rem;
  }
  
  .quiz-progress-text {
    font-size: 0.85rem;
  }
  
  .quiz-progress-dot {
    width: 14px;
    height: 14px;
  }
  
  .quiz-open-answer textarea {
    font-size: 0.95rem;
    padding: 0.75rem;
  }
  
  .quiz-results, 
  .quiz-summary-item {
    padding: 1rem;
  }
  
  .quiz-score {
    font-size: 1.25rem;
    margin: 1rem 0;
  }
}

/* Stili per dispositivi molto piccoli */
@media (max-width: 480px) {
  .quiz-game {
    width: 95%;
  }
  
  .quiz-progress {
    width: 85%;
  }
  
  .quiz-question {
    padding: 1.25rem;
    min-height: 200px;
  }
  
  .quiz-question-title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .quiz-option {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
    border-radius: 1.25rem;
  }
  
  .quiz-button {
    min-width: 90px;
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
    min-height: 42px;
  }
  
  .quiz-progress-bar {
    height: 6px;
  }
  
  .quiz-open-answer textarea {
    min-height: 100px;
  }
}

/* Fix per touch devices */
@media (hover: none) {
  .quiz-option:hover {
    transform: none;
    box-shadow: none;
    border-color: #eaeaea;
    background-color: white;
  }
  
  .quiz-option:active {
    transform: scale(0.98);
    border-color: #df4634;
    background-color: #fdf5f4;
  }
  
  .quiz-option.selected:hover {
    background-color: #DF4634;
    color: white;
    border-color: #DF4634;
  }
}
  