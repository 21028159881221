/* File: src/components/styles/Benefits.css */

/* Reset */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Main container */
.benefits-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem 1.25rem;
    background: #ffffff; /* Sfondo bianco */
    max-width: 1400px;
    margin: 20px auto;
    color: #2b2e34;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    margin-top: 5%;
}

/* Titolo Centrale */
.benefits-title {
    text-align: center;
    margin-bottom: 3rem;
}

.benefits-title h2 {
    font-size: clamp(24px, 4vw, 48px);
    font-weight: 900;
    margin-bottom: 1rem;
    background: linear-gradient(90deg, #df4634, #79261c);
    -webkit-background-clip: text;
    color: transparent;
}

.benefits-title p {
    font-size: clamp(14px, 2vw, 24px);
    font-weight: 400;
    color: #555555;
}

/* Row */
.row {
    display: grid;
    width: 75%;
    margin-bottom: 40px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 1fr; 
    gap: 20px;
}

.row.reverse {
    flex-direction: row-reverse;
}

/* Column */
.column {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 20px;
}

.row > .column:not(:last-child) {
    margin-right: 5%;
}

/* Flip Card */
.flip-card {
    background-color: #f9f9f9;
    border-radius: 20px;
    perspective: 1000px;
    position: relative;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    aspect-ratio: 1 / 1;
}

.flip-card:hover {
    transform: translateY(-10px);
}

.flip-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 0, 0, 0.1), transparent);
    transform: skewX(45deg);
    transition: all 0.5s;
}

.flip-card:hover::before {
    left: 150%;
}

.flip-card:hover {
    box-shadow: 0 8px 16px rgba(223, 70, 52, 0.3);
}

/* Flip Card Inner */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}

/* Flip effect */
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

/* Front and back faces */
.flip-card-front,
.flip-card-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    font-family: 'Inter', sans-serif;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
}

.flip-card-front {
    z-index: 2;
}

.flip-card-back {
    transform: rotateY(180deg);
    text-align: center;
    background-color: rgba(223, 70, 52, 0.8);
}

/* Metric Content */
.metric-content {
    text-align: center;
}

.metric-icon {
    width: 5rem; 
    height: auto;
    object-fit: contain;
    margin-bottom: 10px;
}

.metric-description {
    font-size: 1rem;
    font-weight: 400;
    color: #2b2e34;
}

/* Back Content */
.back-content {
    font-size: 1rem;
    color: white;
}

/* Testimonial Box */
.testimonial-box {
    background-color: #f1f1f1;
    border-radius: 20px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.3s ease;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    aspect-ratio: 4 / 2; 
}

.testimonial-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(223, 70, 52, 0.3);
}

.testimonial-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 0, 0, 0.1), transparent);
    transform: skewX(45deg);
    transition: all 0.5s;
}

.testimonial-box:hover::before {
    left: 150%;
}

/* Testimonial Text */
.testimonial-quote {
    font-style: italic;
    margin-bottom: 1rem;
    color: #2b2e34;
    text-align: center;
    font-size: 1rem;
}

.testimonial-author {
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #df4634;
    text-align: center;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .flip-card {
        aspect-ratio: auto;
        width: 50%;
        height: auto;
    }
}
