/* Contenitore principale */
.documentDetail_MainContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
}

/* Contenitore del contenuto */
.documentDetail_ContentContainer {
  flex: 1;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #fafafa;
}

/* Contenuto principale */
.documentDetail_MainContent {
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.08);
  padding: 30px;
  overflow: hidden;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Titolo principale e sottotitolo */
.documentDetail_MainTitle {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 0.5rem;
  position: relative;
  display: inline-block;
}

.documentDetail_MainTitle::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 60px;
  height: 3px;
  background-color: #df4634;
  border-radius: 3px;
}

.documentDetail_MainSubtitle {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

/* Grid del contenuto */
.documentDetail_ContentGrid {
  margin-top: 20px;
}

/* Box di caricamento */
.documentDetail_LoadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

/* Thumbnails a sinistra */
.documentDetail_Thumbnails {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.documentDetail_Thumbnail {
  cursor: pointer;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.2s ease;
  position: relative;
  height: 100px;
}

.documentDetail_Thumbnail img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.documentDetail_Thumbnail.active {
  border-color: #df4634;
}

.documentDetail_Thumbnail:hover {
  border-color: #df4634;
  transform: translateY(-2px);
}

/* Segnaposto per le thumbnails */
.documentDetail_ThumbnailPlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  color: #777;
  text-align: center;
  padding: 10px;
}

/* Numero pagina */
.documentDetail_ThumbnailPageNumber {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 0.7rem;
  padding: 2px 6px;
  border-top-left-radius: 4px;
}

/* Contenitore anteprima */
.documentDetail_PreviewContainer {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.documentDetail_MainPreview {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
  max-height: 450px;
}

/* Contenitore dell'iframe */
.documentDetail_IframeContainer {
  width: 100%;
  height: 500px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f9f9f9;
}

/* Stili per object ed embed */
.documentDetail_Iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
}

/* Messaggio di fallback */
.documentDetail_IframeContainer p {
  padding: 20px;
  text-align: center;
  color: #666;
}

.documentDetail_IframeContainer a {
  color: #df4634;
  text-decoration: none;
  font-weight: bold;
}

.documentDetail_IframeContainer a:hover {
  text-decoration: underline;
}

/* Azioni per l'anteprima */
.documentDetail_PreviewActions {
  padding: 16px;
  background-color: #f9f9f9;
  border-top: 1px solid #eee;
}

.documentDetail_DownloadBtn {
  background-color: #df4634 !important;
  color: #fff !important;
  font-weight: 500 !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 2px 8px rgba(223, 70, 52, 0.2) !important;
}

.documentDetail_DownloadBtn:hover {
  background-color: #c73a28 !important;
  box-shadow: 0 4px 12px rgba(223, 70, 52, 0.3) !important;
  transform: translateY(-1px);
}

.documentDetail_ViewBtn {
  color: #df4634 !important;
  border-color: #df4634 !important;
  font-weight: 500 !important;
}

.documentDetail_ViewBtn:hover {
  background-color: rgba(223, 70, 52, 0.05) !important;
}

/* Contenitore per il contenuto aggiuntivo sotto l'anteprima */
.documentDetail_AdditionalContent {
  padding: 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: 1px solid #eee;
}

.documentDetail_AdditionalContent .documentDetail_InfoSection {
  margin-bottom: 0;
}

.documentDetail_AdditionalContent .documentDetail_Description {
  max-height: 120px;
  overflow-y: auto;
  margin-bottom: 0;
}

.documentDetail_AdditionalContent .documentDetail_AuthorSection {
  padding: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.documentDetail_AdditionalContent .documentDetail_SocialLinks {
  padding: 0;
  margin-top: 0;
  border-top: none;
}

/* Pannello informazioni */
.documentDetail_InfoPanel {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.04);
  height: auto;
  min-height: 200px;
}

.documentDetail_InfoPanel:hover {
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.09);
}

.documentDetail_InfoSection {
  margin-bottom: 20px;
  position: relative;
}

.documentDetail_InfoSection:last-child {
  margin-bottom: 0;
}

/* Layout compatto per le informazioni */
.documentDetail_CompactInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 0;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 16px;
}

.documentDetail_InfoItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: all 0.3s ease;
  background-color: white;
  border-radius: 6px;
  padding: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
}

.documentDetail_InfoItem:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

.documentDetail_InfoLabel {
  font-weight: 600;
  color: #444;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
  position: relative;
}

.documentDetail_InfoLabel::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 16px;
  background-color: #df4634;
  margin-right: 8px;
  border-radius: 2px;
}

.documentDetail_InfoValue {
  flex: 1;
  color: #333;
  padding-top: 4px;
}

/* Chip per premium e nuovo */
.documentDetail_PremiumChip {
  background-color: #df4634 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.documentDetail_NewChip {
  background-color: #4caf50 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.documentDetail_DateChip {
  font-size: 11px !important;
  color: #666 !important;
  border-color: #ddd !important;
  background-color: #f8f8f8 !important;
}

.documentDetail_DateChip svg {
  color: #999 !important;
}

/* Stili per i tag */
.documentDetail_Tag {
  background-color: white;
  border: 1px solid #e0e0e0;
  color: #555;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 16px;
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease;
  padding: 4px 12px;
}

.documentDetail_Tag:hover {
  background-color: #f0f0f0;
  border-color: #d0d0d0;
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Stile per il chip che mostra più tag */
.documentDetail_MoreTag {
  background-color: #f2f2f2;
  border: 1px dashed #d0d0d0;
  color: #666;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 16px;
  font-weight: 600;
  padding: 4px 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.documentDetail_MoreTag:hover {
  background-color: #e8e8e8;
  border-color: #bbb;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
}

/* Stili per la sezione autore */
.documentDetail_AuthorSection {
  display: flex;
  margin: 16px 0;
}

.documentDetail_AuthorLogo {
  border: 1px solid #eaeaea;
  background-color: #fff;
}

/* Stili per i social link */
.documentDetail_SocialLinks {
  padding: 16px 0;
  border-top: 1px solid #f0f0f0;
  margin-top: 16px;
}

.documentDetail_SocialGrid {
  margin-top: 12px;
}

.documentDetail_SocialIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  color: #666;
  background-color: #f5f5f5;
  transition: all 0.3s ease;
}

.documentDetail_SocialIcon:hover {
  background-color: #df4634;
  color: white;
  transform: scale(1.1);
}

.documentDetail_PlatformLink {
  display: inline-block;
  margin-right: 12px;
  padding: 6px 12px;
  background-color: #f5f5f5;
  border-radius: 4px;
  color: #666;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.documentDetail_PlatformLink:hover {
  background-color: white;
  border-color: #df4634;
  color: #df4634;
  text-decoration: none;
}

/* Descrizione */
.documentDetail_Description {
  font-size: 14px;
  line-height: 1.6;
  color: #444;
  background-color: #fafafa;
  padding: 12px;
  border-radius: 8px;
  border-left: 3px solid #df4634;
}

/* Media Queries per dispositivi mobili */
/* Stili per i metadati (Gradi e Tipo) */
.documentDetail_MetadataGrid {
  margin: 16px 0;
}

.documentDetail_MetadataItem {
  display: flex;
  align-items: center;
  gap: 12px;
}

.documentDetail_MetadataIcon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  color: white;
}

.documentDetail_GradiIcon {
  background: linear-gradient(135deg, #df4634 0%, #f76f62 100%);
  box-shadow: 0 2px 5px rgba(223, 70, 52, 0.3);
}

.documentDetail_MaterialeIcon {
  background: linear-gradient(135deg, #3949ab 0%, #5c6bc0 100%);
  box-shadow: 0 2px 5px rgba(57, 73, 171, 0.3);
}

.documentDetail_MetadataLabel {
  font-size: 0.75rem !important;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 2px;
}

.documentDetail_MetadataValue {
  font-weight: 500 !important;
  font-size: 0.9rem !important;
  color: #333;
  line-height: 1.4;
}

/* Media queries per layout responsive */
@media (max-width: 960px) {
  .documentDetail_AdditionalContent {
    padding: 16px;
  }
  
  .documentDetail_MainPreview,
  .documentDetail_IframeContainer {
    max-height: 400px;
    height: 400px;
  }
}

@media (max-width: 768px) {
  .documentDetail_ContentGrid {
    display: flex;
    flex-direction: column;
  }
  
  .documentDetail_MainPreview,
  .documentDetail_IframeContainer {
    max-height: 350px;
    height: 350px;
  }
  
  .documentDetail_InfoPanel {
    margin-top: 20px;
  }
  
  .documentDetail_AdditionalContent {
    padding: 16px;
  }
  
  .documentDetail_MainTitle {
    font-size: 1.5rem;
  }

  .documentDetail_MainSubtitle {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .documentDetail_ContentContainer {
    padding: 20px 15px;
  }

  .documentDetail_MainContent {
    padding: 15px;
  }

  .documentDetail_Thumbnails {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
  }

  .documentDetail_Thumbnail {
    width: calc(25% - 10px);
    max-width: 80px;
  }

  .documentDetail_PreviewActions {
    padding: 15px;
  }

  .documentDetail_InfoSection {
    margin-bottom: 12px;
  }
  
  .documentDetail_MetadataIcon {
    width: 28px;
    height: 28px;
    font-size: 14px;
  }
  
  .documentDetail_MetadataLabel {
    font-size: 0.7rem !important;
  }
  
  .documentDetail_MetadataValue {
    font-size: 0.85rem !important;
  }
  
  .documentDetail_InfoItem {
    flex-direction: column;
    gap: 2px;
  }
  
  .documentDetail_InfoLabel {
    min-width: auto;
  }
}

/* Stile per l'icona PDF nelle miniature */
.documentDetail_PdfIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #df4634;
}

.documentDetail_PdfIcon svg {
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
}