/* File: src/components/styles/Services.css */

/* Animazioni Keyframes */
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes detailsFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Contenitore principale */
  .services-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem 1.25rem;
    background: linear-gradient(180deg, #DF4634 0%, #000000 100%);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) inset;
    max-width: 1600px;
    margin: 20px auto;
    color: white;
    box-sizing: border-box;
    margin-top: 5%;
  }
  
  /* Sezione introduttiva */
  .services-intro {
    width: 100%;
    text-align: right;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .services-intro-text {
    max-width: 800px;
    margin-left: auto;
  }
  
  .services-intro-text h1 {
    font-size: clamp(26px, 5vw, 70px);
    font-family: 'Inter';
    font-weight: 900;
    line-height: clamp(35px, 5vw, 75px);
    word-wrap: break-word;
    color: white;
    margin-bottom: 20px;
  }
  
  .services-intro-text p {
    font-size: clamp(14px, 2vw, 20px);
    font-family: 'Inter';
    font-weight: 300;
    line-height: clamp(22px, 4vw, 32px);
    color: white;
    margin-bottom: 5%;
  }
  
  /* Griglia dei servizi */
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1.5rem;
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
    margin-bottom: 0rem;
  }
  
  /* Card singola del servizio */
  .service-card {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 1.5rem;
    padding: 1.5rem;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease,
      border-color 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    flex-shrink: 0;
    opacity: 0;
    animation: fadeInUp 0.5s forwards;
    animation-delay: 0.1s;
  }
  
  /* Ritardi per l'animazione a cascata */
  .service-card:nth-child(2) {
    animation-delay: 0.2s;
  }
  .service-card:nth-child(3) {
    animation-delay: 0.3s;
  }
  .service-card:nth-child(4) {
    animation-delay: 0.4s;
  }
  .service-card:nth-child(5) {
    animation-delay: 0.5s;
  }
  
  /* Effetto hover */
  .service-card:hover {
    transform: translateY(-10px) rotate(2deg);
    border-color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease, background-color 0.3s ease,
      border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Stato attivo della card */
  .service-card.active {
    border-color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  /* Titolo del servizio */
  .service-title {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }
  
  /* Icona del servizio */
  .service-icon {
    width: auto;
    height: 150px;
    margin-bottom: 0;
    object-fit: contain;
  }
  
  /* Divisore */
  .services-divider {
    width: 95%;
    border: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.3);
    margin: 2rem 0;
    align-self: center;
    border-radius: 2px;
  }
  
  /* Sezione dei dettagli */
  .details-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 2rem;
    padding: 0 2rem;
    box-sizing: border-box;
    flex-wrap: wrap;
    opacity: 0;
    transform: translateY(20px);
    animation: detailsFadeIn 0.5s forwards;
    animation-delay: 0.5s;
  }
  
  /* Lato sinistro dei dettagli */
  .details-left {
    flex: 1 1 300px;
    color: white;
    transition: opacity 0.3s ease-in-out;
  }
  
  /* Titolo dei dettagli */
  .details-title {
    text-align: center;
    margin-top: 1rem;
    font-size: clamp(24px, 2vw, 30px);
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    line-height: clamp(28px, 4vw, 52px);
    word-wrap: break-word;
    color: white;
  }
  
  /* Lato destro dei dettagli */
  .details-right {
    flex: 1 1 300px;
    color: white;
  }
  
  /* Dettagli del servizio selezionato */
  .service-details {
    padding: 1.5rem;
    text-align: left;
    color: #D3D3D3;
    font-family: 'Inter', sans-serif;
    opacity: 1;
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    transform: translateY(0);
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .service-details.fade-out {
    opacity: 0;
    transform: translateY(-20px);
  }
  
  .service-details.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Titolo e testo dei dettagli */
  .service-details-title {
    font-size: 1.8rem;
    color: #FFFFFF;
    margin-bottom: 1rem;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    margin-left: 0;
  }
  .service-details-text {
    font-size: 1.1rem;
    color: white;
    line-height: 1.6;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    margin-left: 0;
  }
  
  /* Stile chat dimostrativa */
  .chat-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .chat-message.user-message {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
  }
  
  .chat-message.user-message .avatar {
    margin-left: 0.5rem;
    margin-right: 0;
  }
  
  .user-avatar {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #000000;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  
  .chat-message.assistant-message {
    display: flex;
    align-items: flex-start;
  }
  
  .assistant-avatar {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #DF4634;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  
  .chat-message p {
    background-color: #ffffff;
    color: #000000;
    padding: 0.75rem 1.5rem;
    border-radius: 1.875rem;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    max-width: 80%;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    white-space: pre-wrap;
    text-align: left;
  }
  
  .typing-indicator {
    display: flex;
    align-items: center;
  }
  .typing-indicator span {
    width: 8px;
    height: 8px;
    background-color: #DF4634;
    border-radius: 50%;
    margin-right: 4px;
    animation: blink 1.4s infinite both;
  }
  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }
  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  
  /* Responsività */
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  
    .service-card {
      width: 100%;
      max-width: 200px;
    }
  
    .service-icon {
      height: 60px;
      width: auto;
    }
  
    .details-left,
    .details-right {
      flex: 1 1 100%;
      padding: 0;
    }
  
    .services-divider {
      width: 90%;
    }
  
    .chat-message p {
      font-size: 0.8rem;
      padding: 0.6rem 1.2rem;
    }
  
    .user-avatar,
    .assistant-avatar {
      width: 2rem;
      height: 2rem;
    }
  }
  
  @media (max-width: 480px) {
    .services-section {
      padding: 1.5rem 0.75rem;
    }
  
    .service-card {
      height: 50px;
    }
  
    .services-grid {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      gap: 0.5rem;
    }
  
    .service-icon {
      display: none;
    }
  
    .service-title {
      font-size: 1rem;
    }
  
    .details-title {
      font-size: clamp(16px, 4vw, 36px);
      margin-top: -1rem;
    }
  
    .service-details-title {
      font-size: 1.5rem;
    }
  
    .service-details-text {
      font-size: 1rem;
    }
  
    .assistant-message p {
      font-size: 0.75rem;
      padding: 0.5rem 1rem;
    }
  
    .services-divider {
      margin-top: 1.2rem;
    }
  
    .chat-message p {
      font-size: 0.7rem;
      padding: 0.5rem 1rem;
    }
  
    .user-avatar,
    .assistant-avatar {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
  