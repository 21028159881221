.game-navigation-bar {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  white-space: nowrap;
}

.game-navigation-bar::-webkit-scrollbar {
  display: none;
}

.game-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  min-width: 80px;
  border: 2px solid #df4634;
  border-radius: 8px;
  background-color: #f7f7f7;
  transition: background-color 0.3s, border 0.3s, box-shadow 0.3s, transform 0.3s;
  flex: 0 0 auto;
}

.game-nav-item:hover {
  background-color: #eaeaea;
}

.game-nav-item.active {
  border: 2px solid #df4634;
  background-color: #ffd9d6;
  box-shadow: 0 0 8px rgba(223, 70, 52, 0.6);
  transform: scale(1.05);
  position: relative;
}

.game-nav-item.active::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  background-color: #df4634;
  border-radius: 50%;
}

.game-nav-item.active .game-nav-title {
  font-weight: bold;
}

.game-nav-item.completed {
  border: 2px solid #19696A;
  background-color: #e8f5f5;  /* Verde molto chiaro e sfumato */
}

/* Stile per gli elementi completati quando sono attivi */
.game-nav-item.completed.active {
  border: 2px solid #19696A;
  background-color: #d1e8e8;  /* Verde leggermente più intenso ma sempre sfumato */
  box-shadow: 0 0 8px rgba(25, 105, 106, 0.6);
  transform: scale(1.05);
  position: relative;
}

.game-nav-item.completed.active::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  background-color: #19696A;
  border-radius: 50%;
}

.game-nav-item.completed .game-nav-title {
  font-weight: bold;
}

.game-nav-icon-wrapper {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f2f2f2;
  margin-bottom: 8px;
  overflow: hidden;
}

.game-nav-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.game-nav-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #df4634;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.game-nav-title {
  font-size: 0.75rem;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  text-align: center;
}

/* Media query per schermi più grandi */
@media (min-width: 768px) {
  .game-navigation-bar {
    justify-content: center;
  }

  .game-nav-item {
    padding: 12px;
  }

  .game-nav-icon-wrapper {
    width: 40px;
    height: 40px;
  }

  .game-nav-number {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }

  .game-nav-title {
    font-size: 0.85rem;
  }
}
