/* CrosswordGame Styles */
.crossword-game {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.crossword-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.crossword-header h2 {
  font-size: 2.2rem;
  color: #34495e;
  margin: 0;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 0.8rem;
  letter-spacing: 0.5px;
}

.crossword-header h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 20%;
  width: 60%;
  height: 4px;
  background: linear-gradient(90deg, #3498db, #9b59b6);
  border-radius: 4px;
}

.crossword-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.crossword-grid-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 3rem;
  width: 100%;
  margin-bottom: 2rem;
  justify-content: center;
}

.crossword-grid-wrapper {
  flex: 0 1 auto;
  background-color: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.crossword-grid {
  border-collapse: collapse;
  margin: 0 auto;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.crossword-row {
  display: table-row;
}

.crossword-cell {
  width: 42px;
  height: 42px;
  border: 1px solid #e0e0e0;
  position: relative;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 0;
  transition: all 0.2s ease;
}

.crossword-cell.blocked {
  background-color: #2c3e50;
  border: 1px solid #2c3e50;
}

.crossword-number {
  position: absolute;
  top: 3px;
  left: 3px;
  font-size: 11px;
  font-weight: 600;
  color: #3498db;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  padding: 1px 3px;
  line-height: 1;
  min-width: 12px;
  text-align: center;
}

.crossword-input {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #2c3e50;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  caret-color: #3498db;
}

.crossword-cell.active {
  background-color: #ebf5fb;
  box-shadow: inset 0 0 0 2px #3498db;
  z-index: 5;
  transform: scale(1.03);
}

.crossword-cell.in-active-word {
  background-color: #e8f6fd;
  z-index: 1;
}

.crossword-cell.correct {
  background-color: #eafaf1;
  box-shadow: inset 0 0 0 1px #2ecc71;
}

.crossword-cell.incorrect {
  background-color: #fdedeb;
  box-shadow: inset 0 0 0 1px #e74c3c;
}

.crossword-legend {
  flex: 0 0 350px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.crossword-tabs {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f6f8fa;
}

.crossword-tab {
  flex: 1;
  padding: 12px 16px;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  color: #606060;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  position: relative;
}

.crossword-tab:hover {
  color: #3498db;
  background-color: #f0f0f0;
}

.crossword-tab.active {
  color: #3498db;
  background-color: #ffffff;
}

.crossword-tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #3498db;
}

.crossword-clues-panel {
  flex: 1;
  overflow-y: auto;
  height: 100%;
  padding: 0;
}

.crossword-clues {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: white;
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}

.crossword-clue {
  display: flex;
  align-items: flex-start;
  padding: 10px 12px;
  margin-bottom: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  line-height: 1.4;
  background-color: #f8f9fa;
  font-size: 0.95rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.crossword-clue:last-child {
  margin-bottom: 0;
}

.crossword-clue-number {
  font-weight: 600;
  color: #3498db;
  margin-right: 8px;
  min-width: 18px;
  display: inline-block;
  text-align: left;
}

.crossword-clue-text {
  flex: 1;
  word-break: break-word;
}

.crossword-clue:hover {
  background-color: #f0f4f8;
  transform: translateX(3px);
}

.crossword-clue.active {
  background-color: #e8f4fd;
  border-left: 3px solid #3498db;
  padding-left: 9px;
  font-weight: 500;
  color: #2c3e50;
}

.crossword-clue.correct {
  background-color: #eafaf1;
  border-left: 3px solid #2ecc71;
  padding-left: 9px;
}

.crossword-clue.incorrect {
  background-color: #fdedeb;
  border-left: 3px solid #e74c3c;
  padding-left: 9px;
}

.crossword-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin-top: 2rem;
}

.crossword-score {
  font-size: 1.6rem;
  font-weight: 700;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 1rem;
  background-color: #f9f9f9;
  padding: 1rem 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #f0f0f0;
}

.crossword-buttons {
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;
  justify-content: center;
}

.crossword-button {
  padding: 0.8rem 1.5rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 6px rgba(52, 152, 219, 0.2);
  min-width: 160px;
  text-align: center;
}

.crossword-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 3px 8px rgba(52, 152, 219, 0.3);
}

.crossword-button.secondary {
  background-color: #e74c3c;
  box-shadow: 0 2px 6px rgba(231, 76, 60, 0.2);
}

.crossword-button.secondary:hover {
  background-color: #c0392b;
  box-shadow: 0 3px 8px rgba(231, 76, 60, 0.3);
}

.crossword-button.submit-final {
  background-color: #27ae60;
  color: white;
  box-shadow: 0 2px 6px rgba(39, 174, 96, 0.2);
}

.crossword-button.submit-final:hover {
  background-color: #219d55;
  box-shadow: 0 3px 8px rgba(39, 174, 96, 0.3);
}

/* Responsive styles */
@media (max-width: 992px) {
  .crossword-grid-container {
    flex-direction: column;
    align-items: center;
  }
  
  .crossword-legend {
    margin-top: 2rem;
    width: 100%;
    flex: 1;
    max-width: none;
    max-height: 400px;
  }
  
  .crossword-header h2 {
    font-size: 1.8rem;
  }
  
  .crossword-clues {
    max-height: 300px;
  }
}

@media (max-width: 768px) {
  .crossword-button {
    width: 100%;
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
  
  .crossword-grid-wrapper {
    padding: 1rem;
  }
  
  .crossword-header h2 {
    font-size: 1.6rem;
  }
  
  .crossword-score {
    font-size: 1.4rem;
    padding: 0.8rem 1.5rem;
  }
  
  .crossword-cell {
    width: 38px;
    height: 38px;
  }
  
  .crossword-input {
    font-size: 1.2rem;
  }
  
  .crossword-grid-container {
    flex-direction: column;
    align-items: center;
  }
  
  .crossword-legend {
    margin-top: 1rem;
    width: 100%;
  }
  
  .crossword-clues {
    max-height: 300px;
  }
  
  .crossword-grid-wrapper {
    padding: 0.75rem;
  }
  
  .crossword-cell {
    width: 36px;
    height: 36px;
  }
  
  .crossword-input {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .crossword-cell {
    width: 32px;
    height: 32px;
  }
  
  .crossword-input {
    font-size: 1rem;
  }
  
  .crossword-number {
    font-size: 9px;
    top: 1px;
    left: 1px;
    padding: 1px 2px;
  }
  
  .crossword-clue {
    padding: 0.8rem;
    font-size: 0.9rem;
  }
  
  .crossword-clue-number {
    min-width: 20px;
    margin-right: 0.5rem;
  }
}

/* Aggiunta di stili per ulteriori varianti e componenti del cruciverba */
.crossword-clues-horizontal h4,
.crossword-clues-vertical h4 {
  color: #34495e;
  margin-bottom: 12px;
  font-size: 1.2rem;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 8px;
}

.crossword-clues li.correct {
  background-color: #eafaf1;
  border-left: 3px solid #2ecc71;
  padding-left: 9px;
}

.crossword-clues li.incorrect {
  background-color: #fdedeb;
  border-left: 3px solid #e74c3c;
  padding-left: 9px;
}

.crossword-clues-horizontal .crossword-clue.active,
.crossword-clues-vertical .crossword-clue.active {
  background-color: #e8f4fd;
  font-weight: 500;
}

.crossword-clues-horizontal .crossword-clue.active {
  border-left: 3px solid #3498db;
}

.crossword-clues-vertical .crossword-clue.active {
  border-left: 3px solid #9b59b6;
} 