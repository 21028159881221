/* ====================================== */
/*         MATCHPAIRS GAME STYLES         */
/* ====================================== */

.matchpairs-game {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Loader per transizione (simile al quiz) */
.matchpairs-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  min-height: 300px;
}

.matchpairs-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #df4634;
  border-radius: 50%;
  animation: matchpairs-spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes matchpairs-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Progress bar */
.matchpairs-progress {
  margin-bottom: 0.75rem;
  position: relative;
  padding: 1.25rem 0 0.5rem 0;
}

.matchpairs-progress-bar {
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
}

.matchpairs-progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #df4634, #ff6b58);
  border-radius: 10px;
  transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  box-shadow: 0 1px 5px rgba(223, 70, 52, 0.3);
}

.matchpairs-progress-fill::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 10px;
}

.matchpairs-progress-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.95rem;
  color: #555;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.matchpairs-progress-text span.current {
  color: #df4634;
  font-weight: 600;
}

/* Set di abbinamenti */
.matchpairs-set {
  background-color: white;
  border-radius: 1rem;
  padding: 2.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  min-height: 300px;
  display: flex;
  flex-direction: column;
}

.matchpairs-set-title {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

/* Container per le coppie */
.matchpairs-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.matchpairs-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 8px;
}

.matchpairs-left {
  font-size: 1rem;
  color: #333;
  font-weight: 500;
}

.matchpairs-select select {
  padding: 0.5rem 1rem;
  border: 2px solid #eaeaea;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.matchpairs-select select:focus {
  outline: none;
  border-color: #df4634;
  box-shadow: 0 0 0 2px rgba(223, 70, 52, 0.1);
}

/* Navigazione all'interno del gioco */
.matchpairs-navigation {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
  padding-bottom: 1.5rem;
}

.matchpairs-button {
  padding: 0.75rem 1.5rem;
  border-radius: 1.875rem;
  font-weight: 500;
  text-transform: none;
  transition: all 0.2s ease;
  min-height: 44px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.matchpairs-button:not(.secondary) {
  background-color: #df4634;
  color: white;
  border: none;
}

.matchpairs-button:not(.secondary):hover {
  background-color: #E57373;
}

.matchpairs-button.secondary {
  background-color: transparent;
  color: #df4634;
  border: 2px solid #df4634;
}

.matchpairs-button.secondary:hover {
  background-color: rgba(223, 70, 52, 0.06);
}

.matchpairs-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Responsive */
@media (max-width: 768px) {
  .matchpairs-set-title {
    font-size: 1.1rem;
  }
  .matchpairs-navigation {
    flex-direction: column;
  }
  .matchpairs-button {
    width: 100%;
  }
}

/* ====================================== */
/*      DETTAGLI E RISULTATI MATCHPAIRS   */
/* ====================================== */

.matchpairs-results {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.matchpairs-score {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1.5rem 0;
}

.matchpairs-summary {
  margin: 2rem 0;
  text-align: left;
}

.matchpairs-summary-item {
  padding: 1rem;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-bottom: 1rem;
  background-color: #f9f9f9;
}

.matchpairs-summary-item.correct {
  border-left: 5px solid #2ecc71;
}

.matchpairs-summary-item.incorrect {
  border-left: 5px solid #e74c3c;
}
